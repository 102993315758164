import {

  ALL_BRANDS_LOADING,
  ALL_BRANDS_SUCCESS,
  ALL_BRANDS_FAIL,
} from "../constants/brandConstants";


// get all product
export const brandReducer = (state = [], action) => {
  switch (action.type) {
    case ALL_BRANDS_LOADING:
      return {
        loading: true,
        ...state,
      };
    case ALL_BRANDS_SUCCESS:
      return {
        loading: false,
        brands: action.payload,
      };
    case ALL_BRANDS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
