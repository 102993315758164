import axios from 'axios';
import { BASE_URL } from "../../config";
import {

  ALL_AGENTS_LOADING,
  ALL_AGENTS_SUCCESS,
  ALL_AGENTS_FAIL,

} from '../constants/agentConstants';



// Get All categories
export const checkAgent = (agentCode) => async (dispatch) => {
  try {
    dispatch({ type: ALL_AGENTS_LOADING });

    const { data } = await axios.get(`${BASE_URL}/api/agent/${agentCode}`);
    console.log(data," check agents");
    if (data) {
      dispatch({
        type: ALL_AGENTS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: ALL_AGENTS_FAIL,
        payload: 'Agent not found',
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_AGENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



