import axios from "axios";
import { BASE_URL } from "../../config";
import {
  ALL_PRODUCTS_FAIL,
  ALL_PRODUCTS_LOADING,
  ALL_PRODUCTS_SUCCESS,
  FETCH_SHOP_PRODUCTS_FAIL,
  FETCH_SHOP_PRODUCTS_LOADING,
  FETCH_SHOP_PRODUCTS_SUCCESS,
  NEW_REVIEW_FAIL,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  PRODUCT_BY_ID_FAIL,
  PRODUCT_BY_ID_REQUEST,
  PRODUCT_BY_ID_SUCCESS,
  FETCH_CATEGORY_PRODUCTS_FAIL,
  FETCH_CATEGORY_PRODUCTS_LOADING,
  FETCH_CATEGORY_PRODUCTS_SUCCESS,
} from "../constants/productConstants";

// Fetch products by brand
export const fetchCategoryProducts =
  ( token, state) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FETCH_CATEGORY_PRODUCTS_LOADING,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
        console.log("fetch cat prod ",state)
      const { data } = await axios.get(
        `${BASE_URL}/api/shop/category-products?state=${state}`,config
      );

      dispatch({
        type: FETCH_CATEGORY_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CATEGORY_PRODUCTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      console.log(error.message, "on brandshop");
    }
  };
// get product by id from DB
export const getProductById = (id,token) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_BY_ID_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const { data } = await axios.get(`${BASE_URL}/api/products/${id}`,config);
    dispatch({
      type: PRODUCT_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    // console.log(error);
  }
};

// Get All Products
export const getAllProduct = (token,stateName) => async (dispatch) => {
  try {
    dispatch({ type: ALL_PRODUCTS_LOADING });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    console.log("Loading prod ACTion --- " + JSON.stringify(config),stateName);
    const { data } = await axios.post(`${BASE_URL}/api/products`,{stateName},config);
    dispatch({
      type: ALL_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// fetch all products from for shop page
export const fetchShopProducts =
  (page = 1, category, sort, search,token,state) =>
  async (dispatch) => {
      console.log(category,"category fetch 1",search)
    try {
      dispatch({
        type: FETCH_SHOP_PRODUCTS_LOADING,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/shop/products?${category}&${sort}&state=${state}&name=${search}`,config
      );

      dispatch({
        type: FETCH_SHOP_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_SHOP_PRODUCTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      // console.log(error);
    }
  };


  // fetch all products from for shop page
export const fetchBrandProducts =
(page = 1, brand, sort, search,token, state) =>
async (dispatch) => {
  console.log("brand: " , brand,"sort: " , sort,"search",search,"page",page,"token",token,"state",state);
  try {
    dispatch({
      type: FETCH_SHOP_PRODUCTS_LOADING,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}/api/shop/brandProducts?brand=${brand}&${sort}&state=${state}&name=${search}`,config
    );

    dispatch({
      type: FETCH_SHOP_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SHOP_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
     console.log(error.message,"on brandshop");
  }
};

// NEW REVIEW
export const newReview = (reviewData) => async (dispatch, getState) => {
  try {
    const token = getState().userLogin?.userInfo?.access_token;
    dispatch({ type: NEW_REVIEW_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const { data } = await axios.put(
      `${BASE_URL}/api/review`,
      reviewData,
      config
    );

    dispatch({
      type: NEW_REVIEW_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: NEW_REVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
