import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBanners } from   "../../../redux/actions/bannerActions";
const HeroSlide = () => {

  const dispatch = useDispatch();
  const { banners, error, loading } = useSelector(
    (state) => state.bannerdata
  );

  const [callback, setCallback] = useState(false);

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch, callback]);
console.log("Loading banners",banners)
  return (
      <>
    {/* <section className="hero-section">
      <div className="hero-slide">
        <Swiper
          modules={[Autoplay]}
          grabCursor={true}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
        >
          {heroSlideData && (
            <>
              {heroSlideData?.map((item, i) => (
                <SwiperSlide key={i}>
                  {({ isActive }) => (
                    <HeroSlideItem
                      item={item}
                      className={`${isActive ? "active" : ""}`}
                    />
                  )}
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      </div>
    </section> */}

    <section className="hero-section">
      <div className="hero-slide">
        <Swiper
          modules={[Autoplay]}
          grabCursor={true}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
        >
          {banners && (
            <>
              {banners?.map((item, i) => (
                <SwiperSlide key={i}>
                  {({ isActive }) => (
                    <HeroSlideItem
                      item={item}
                      className={`${isActive ? "active" : ""}`}
                    />
                  )}
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      </div>
    </section>
                    </>
    
  );
};

const HeroSlideItem = ({ item, className }) => (
  <section className="bannerSection">
    <div
      className={`bannerSlider hero-slide__item ${className}`}
      style={{ backgroundImage: `url(${item.image})` }}
    >
      <div className="hero-slide__item__content container-div">
        <div className="hero-slide__item__content__info">
          <h3 className="overview">{item.description}</h3>
          <h2 className="title">{item.title}</h2>
          <div className="btns">
            <Link to={`product/${item.productID}`}>
              <button className="shop-now">Shop Now</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HeroSlide;
