import {
    FETCH_PROMOS_REQUEST,
    FETCH_PROMOS_SUCCESS,
    FETCH_PROMOS_FAILURE,
    CREATE_PROMO_REQUEST,
    CREATE_PROMO_SUCCESS,
    CREATE_PROMO_FAILURE,
    UPDATE_PROMO_REQUEST,
    UPDATE_PROMO_SUCCESS,
    UPDATE_PROMO_FAILURE,
    DELETE_PROMO_REQUEST,
    DELETE_PROMO_SUCCESS,
    DELETE_PROMO_FAILURE,
  } from '../constants/promoConstants';
  
  const initialState = {
    promos: [],
    loading: false,
    error: null,
  };
  
  const promoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROMOS_REQUEST:
      case CREATE_PROMO_REQUEST:
      case UPDATE_PROMO_REQUEST:
      case DELETE_PROMO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_PROMOS_SUCCESS:
        return {
          ...state,
          promos: action.payload,
          loading: false,
          error: null,
        };
      case CREATE_PROMO_SUCCESS:
        return {
          ...state,
          promos: [...state.promos, action.payload],
          loading: false,
          error: null,
        };
      case UPDATE_PROMO_SUCCESS:
        const updatedPromos = state.promos.map((promo) =>
          promo._id === action.payload._id ? action.payload : promo
        );
        return {
          ...state,
          promos: updatedPromos,
          loading: false,
          error: null,
        };
      case DELETE_PROMO_SUCCESS:
        const filteredPromos = state.promos.filter(
          (promo) => promo._id !== action.payload
        );
        return {
          ...state,
          promos: filteredPromos,
          loading: false,
          error: null,
        };
      case FETCH_PROMOS_FAILURE:
      case CREATE_PROMO_FAILURE:
      case UPDATE_PROMO_FAILURE:
      case DELETE_PROMO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default promoReducer;
  