import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-bootstrap";
import {
  FiShoppingCart,
  FiHeart,
  FiStar,
  FiPackage,
  FiTag,
} from "react-icons/fi";
import { getAllCategories } from "../../../redux/actions/categoryAction";
import "./cat.scss";
import { Link } from "react-router-dom";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
const CategorySlide = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.allCategories);
  const token = useSelector((state) => state.userLogin?.userInfo?.access_token);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    dispatch(getAllCategories(token));
  }, [dispatch,token]);

  useEffect(() => {
    // Add event listener to handle screen width changes
    window.addEventListener("resize", handleResize);
    // Initial check for screen width on component mount
    handleResize();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle screen width changes
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const numAds = 10; // Total number of ads
  const maxAdsPerWidth = 4; // Maximum number of ads to show per available width

  const showAds = Math.min(maxAdsPerWidth, numAds);
  console.log(showAds);
  const icons = [
    FiShoppingCart,
    FiHeart,
    FiStar,
    FiPackage,
    FiTag,
    FiHeart,
    FiStar,
    FiPackage,
    FiTag,
    FiHeart,
    FiStar,
    FiPackage,
    FiTag,
  ];

  return (
    <>
      <section className="carousel-wrapper">
        <Carousel
          className="CMain custom-carousel"
          indicators={false}
          controls={isMobile}
          fade={false}
          pause={false}
          interval={null}
          touch={isMobile}
        >
          <Carousel.Item>
            <div className="category-icon-slide">
              {categories?.map((data, index) => (
                <div
                  key={index}
                  className="category-icon"
                  style={{
                    width: isMobile ? "14%" : "8%",
                    transform: isMobile ? "scale(0.6)" : "", // Adjust the scale for mobile view
                  }}
                >
                  <Link to={`/shop/${data.name}`}>
                  <img
                    src={data.image}
                    alt={data.name}
                    style={{ verticalAlign: "baseline" }}
                  />
                  
                    <p
                      style={{
                        textAlign: "center",
                        fontSize:  isMobile ? "0.62rem":"0.72rem",
                        transform: isMobile ? "scale(1.6)" : "", // Adjust the scale for desktop view
                        width: "100%",
                        wordWrap: "break-word",
                        lineHeight: 1,
                      }}
                    >
                      {data.name}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
};

export default CategorySlide;
