// reducer.js
import * as types from '../constants/otpConstants';


const initialState = {
  loading: false,
  error: null,
};

export const otpRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OTP_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
          otpMatch: null,
      };
    case types.OTP_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        otpMatch: action.payload.otpMatch,
      };
    case types.OTP_REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        otpMatch: null,
      };
    default:
      return state;
  }
};

