import { useEffect, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import {
  ActivationEmail,
  ConfirmOrder,
  ForgotPassword,
  Login,
  MyOrders,
  NotFound,
  OrderDetails,
  OrderSuccess,
  Payment,
  BuyPayment,
  Profile,
  ProtectedRoute,
  Register,
  ResetPassword,
  Shipping,
} from "./pages";
import { refreshToken } from "./redux/actions/userActions";
import "./styles/styles.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Rings } from "react-loader-spinner";
import { Header } from "./components";
import BuyOrder from "./pages/BuyOrder/BuyOrder";
import { ErrorBoundary } from 'react-error-boundary';
const UserDashboard = lazy(() =>
  import("./components/Manager/UserDashboard/UserDashboard")
);
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));

const Blog = lazy(() => import("./pages/Blog/Blog"));

const Contact = lazy(() => import("./pages/Contact/Contact"));

const Home = lazy(() => import("./pages/Home/Home"));

const ProductDetail = lazy(() => import("./pages/ProductDetail/ProductDetail"));

const Shop = lazy(() => import("./pages/Shop/Shop"));

const BrandShop = lazy(() => import("./pages/Shop/BrandShop"));

// home pages

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshToken());
  }, [dispatch]);

  const user = useSelector((state) => state?.userLogin?.userInfo);
  console.log("APP JS USER",user);
  return (
    <>
       <ToastProvider placement="top-right">
        <BrowserRouter>
          <Header />
          <ErrorBoundary
            FallbackComponent={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Try again</button>
              </div>
            )}
          >
          <Suspense
            fallback={
              <Rings
                ariaLabel="loading-indicator"
                color="#f3b632"
                height={100}
                width={100}
              />
            }
          >
            <Routes>
              {/* {user?.access_token && user?.user?.role === 0 && ( */}
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />

              <Route
                path="product/:productId"
                element={
                  <ProtectedRoute>
                    <ProductDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="shop"
                element={
                  <ProtectedRoute>
                    <Shop />
                  </ProtectedRoute>
                }
              />
              <Route
                path="shop/:categoryName"
                element={
                  <ProtectedRoute>
                    <Shop />
                  </ProtectedRoute>
                }
              />
              <Route
                path="brandshop"
                element={
                  <ProtectedRoute>
                    <BrandShop />
                  </ProtectedRoute>
                }
              />
              <Route
                path="brandshop/:brandName"
                element={
                  <ProtectedRoute>
                    <BrandShop />
                  </ProtectedRoute>
                }
              />
              <Route
                path="about"
                element={
                  <ProtectedRoute>
                    <AboutUs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="blog"
                element={
                  <ProtectedRoute>
                    <Blog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="contact"
                element={
                  <ProtectedRoute>
                    <Contact />
                  </ProtectedRoute>
                }
              />

              <Route
                path="shipping"
                element={
                  <ProtectedRoute>
                    <Shipping />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="shipping/:ID"
                element={
                  <ProtectedRoute>
                    <Shipping />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/order/confirm"
                element={
                  <ProtectedRoute>
                    <ConfirmOrder />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/order/buyNow"
                element={
                  <ProtectedRoute>
                    <BuyOrder />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/process/payment"
                element={
                  <ProtectedRoute>
                    <Payment />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/process/buypayment"
                element={
                  <ProtectedRoute>
                    <BuyPayment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/success"
                element={
                  <ProtectedRoute>
                    <OrderSuccess />
                  </ProtectedRoute>
                }
              />

              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <UserDashboard />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Profile />} />
                <Route path="myorders" element={<MyOrders />} />
                <Route path="order/:orderId" element={<OrderDetails />} />
              </Route>
              {/* )} */}        
              <Route
                path="register"
                element={
                  user?.access_token ? (
                    <>
                      {console.log("Access token exists:", user.access_token)}
                      <Navigate to="/" />
                    </>
                  ) : (
                      
                      <>
                       {console.log("Access NOts  exists:", user)}
                       <Register />
                      </>
                  
                  )
                }
              />
              <Route path="login" element={<Login />} />
              <Route
                path="forgot"
                element={
                  user?.access_token ? <Navigate to="/" /> : <ForgotPassword />
                }
              />
              <Route path="user/reset/:token" element={<ResetPassword />} />
              <Route path="*" element={<Login />} />
            </Routes>
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </ToastProvider>
    </>
  );
}

export default App;
