import { Link } from "react-router-dom";


import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPromos } from   "../../../redux/actions/promoActions";
const DiscountPromo = () => {
  const dispatch = useDispatch();
  const { promos, error, loading } = useSelector(
    (state) => state.promodata
  );

  const [callback, setCallback] = useState(false);

  useEffect(() => {
    dispatch(fetchPromos());

    console.log("fetching promo");
  }, [dispatch, callback]);
console.log("Loading promos",promos[0]?.image)
return (
  <div className="discount-section">
   <div className="discount" style={{ backgroundImage: promos[0]?.image ? `url(${promos[0]?.image})` : 'url(http://52.66.245.93:444/1701841906282-WhatsApp%20Image%202023-12-06%20at%2011.19.52_d7673b33.jpg)' }}>
 
      <div className="discount__overlay"></div>
      <div className="discount__content container-div">
        <h2>Latest & Special Brand</h2>

        <Link to="/shop">
          <button className="button">Shop Now</button>
        </Link>
      </div>
    </div>
  </div>
)};

export default DiscountPromo;
