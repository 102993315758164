import {
  ALL_AGENTS_LOADING,
  ALL_AGENTS_SUCCESS,
  ALL_AGENTS_FAIL,
} from '../constants/agentConstants';

const initialState = {
  agents: [],
  loading: false,
  error: null,
};

export const agentCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_AGENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ALL_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.payload,
      };
    case ALL_AGENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

