import React, { useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { BsHandbag } from "react-icons/bs";
import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";
import { BiLogOutCircle, BiShoppingBag, BiGridAlt } from "react-icons/bi";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import CryptoJS from "crypto-js";


import { useSelector, useDispatch } from "react-redux";

import { useToasts } from "react-toast-notifications";
import { Cart, WishList } from "../../../pages";
import { logo } from "../../../assets";
import { logout } from "../../../redux/actions/userActions";
import { USER_LOGOUT_RESET } from "../../../redux/constants/userConstants";
import FlyoutMenu from "./flyout";
import {fetchCategoryProducts} from "../../../redux/actions/productActions";



const Header = () => {

  const [isService1Open, setService1Open] = useState(false);
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.allCategories);

  useEffect(() => {
    // Dispatch the fetchCategoryProducts action
    dispatch(fetchCategoryProducts());
  }, [dispatch]);
  
  const handleService1Click = (e) => {
    e.stopPropagation();
    setService1Open(!isService1Open);
  };

  const handleService1ItemClick = (e) => {
    e.stopPropagation();
    setService1Open(false);
  };
  const { categoryProducts } = useSelector(
    (state) => state.categoryProducts
  );

    console.log(categoryProducts?.result,"cat Prods");

  const { brands, loading } = useSelector(
    (state) => state.allBrands
  );
  const brandItems = brands?.map((data, index) => ({
    item: data.name,
    path: `/brandShop/${data.name}`,
    icons: data?.image || "default",
    
  }));
  const categoryItems = categoryProducts?.result?.map((data) => (
      //console.log("s12341234"),
    {
    item: data.categoryDetails.name,
    path: `/shop/${data.categoryDetails.name}`,
    icons: data?.categoryDetails.image || "default",
   // Use productNames as subcategories
    dropdown: data.productNames?.map((productName, pindex) => ({
     // subcategories: data.productNames,
      item: productName,
      path: pindex,
    })),
  }));

  const menus = [
    {
      item: "Home",
      path: "/",
    },
    
            {
              item: "Categories", icon: 'pi pi-fw pi-users',
              path: "/shop",
              submenu: categoryItems,
            },

             {
               item: "Brands",
               path: "/",
               submenu: brandItems,
             },
             {
               item: "Blog",
               path: "/blog",
               submenu: [
                 {
                   item: "Latest Blog",
                   path: "/latestblog",
                 },
                 {
                   item: "Submenu2",
                   path: "/submenu",
                 },
               ],
             },
            //  {
            //    item: "Contact",
            //    path: "/contact",
            //    submenu: [
            //      {
            //        item: "Contact",
            //        path: "/contact",
            //      },
            //      {
            //        item: "Submenu3",
            //        path: "/submenu3",
            //      },
            //    ],
            //  },
            ]
  const navigate = useNavigate();
  const location = useLocation();


  const { addToast } = useToasts();
  const [currentLink, setCurrentLink] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isWishListOpen, setisWishListOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;
  const [search, setSearch] = useState("");
  const logoutUser = useSelector((state) => state.userLogout);
  const { userLogout, error } = logoutUser;
  const { cartItems } = useSelector((state) => state.cart);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const toggleCart = () => {
    setCartOpen(false);
  };
  const [isMobile, setIsMobile] = useState(false);
  const toogleWishList = () => {
    setisWishListOpen(false);
  };

  const toggleSubMenu = (index) => {
    setCurrentLink(currentLink === index ? null : index);
  };

  const handleLogout = () => {
    if (!user?.userInfo?.access_token) return;
    dispatch(logout(userInfo?.access_token));
  };
   
  const handleDropdownClick = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleSubmenuClick = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const handleSearch = () => {
    navigate(`/shop?search=${search}`);
  };
  const handleFlyoutMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Logout logic
    if (error) {
      dispatch({ type: USER_LOGOUT_RESET });
      addToast(error, { appearance: "error", autoDismiss: true });
    } else if (userLogout) {
      dispatch({ type: USER_LOGOUT_RESET });
      addToast(userLogout?.message, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate("/");
    }
  
    // Screen width change logic
    window.addEventListener("resize", handleResize);
    // Initial check for screen width on component mount
    handleResize();
  
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userLogout, error, addToast, dispatch, navigate]);
  
  



  return (
    <>
    <header className="header scroll-header">
    <nav className="nav container-div">
      <div className="nav__logo">
        <Link to="/">
          <img
            src={logo}
            alt="D2U Store"
          />
        </Link>
      </div>

      {/* Flyout Right Menu */}
      <div className={`flyout-menu ${menuOpen ? "open" : ""}`}>
   
          {isMobile && <FlyoutMenu menus={menus} />}
          </div>

        {/* <div
          className="nav__close"
          id="nav-close"
          onClick={() => setMenuOpen(false)}
        >
          <HiX />
        </div> */}
      {/* </div> */}

          {location.pathname !== "/shop" && (
         <div className="shop__filter__searchHome">
         <input
           type="text"
           value={search}
           placeholder="Enter your search!"
           onChange={(e) => setSearch(e.target.value)}
           onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
         />
         <div className="search-icon" onClick={handleSearch}>
           <AiOutlineSearch />
         </div>
       </div>
       
        )}
      <div className="nav__icons">
        <ul className="nav__list">
          <li className="nav__item">
            <Link to="/" className="nav__mobileMenu nav__link">
              <AiOutlineHome />
            </Link>
          </li>

          <NavDropdown
            title={
              userInfo?.user?.avatar ? (
                <img
                  title={userInfo?.user?.name}
                  style={{ borderRadius: "50%" }}
                  width="22"
                  height="22"
                  src={userInfo?.user?.avatar}
                  alt="user"
                />
              ) : (
                <AiOutlineUser className="nav__dropdown-icon" />
              )
            }
            id="collasible-nav-dropdown"
          >
            {userInfo?.user ? (
              <>
                <NavDropdown.Item
                  className="nav__dropdown__item"
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="nav__dropdown__item"
                  onClick={handleLogout}
                >
                  Logout
                </NavDropdown.Item>
              </>
            ) : (
              <>
                <NavDropdown.Item
                  className="nav__dropdown__item"
                  onClick={() => navigate("/login")}
                >
                  Login
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="nav__dropdown__item"
                  onClick={() => navigate("/register")}
                >
                  Register
                </NavDropdown.Item>
              </>
            )}
          </NavDropdown>


          <li className="nav__item">
            <a
              href="#contact"
              className="nav__wrapper nav__link"
              aria-expanded={isCartOpen ? "true" : "false"}
              onClick={() => {
                setCartOpen((prev) => !prev);
              }}
            >
              <BsHandbag />
              <span className="nav__icons__cart">{cartItems?.length}</span>
            </a>
          </li>
        </ul>
      </div>

    </nav>

    {/* hidden cart drawer */}
    <div className={isCartOpen ? "mini-cart-open" : ""}>
      <div className="mini-cart">
        <Cart setCartOpen={setCartOpen} isCartOpen={isCartOpen} />
      </div>
      <div
        className={
          isCartOpen ? "drawer-backdrop dark-overflow" : "drawer-backdrop"
        }
        onClick={toggleCart}
      />
    </div>

    {/* hidden wishlist drawer */}
    <div className={isWishListOpen ? "mini-cart-open" : ""}>
      <div className="mini-cart">
        <WishList
          setisWishListOpen={setisWishListOpen}
          isWishListOpen={isWishListOpen}
        />
      </div>
      <div
        className={
          isWishListOpen ? "drawer-backdrop dark-overflow" : "drawer-backdrop"
        }
        onClick={toogleWishList}
      />
    </div>
  </header>
</>
);
};

export default Header;