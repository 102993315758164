import axios from 'axios';
import { BASE_URL } from "../../config";
import {
  FETCH_PROMOS_REQUEST,
  FETCH_PROMOS_SUCCESS,
  FETCH_PROMOS_FAILURE,

} from '../constants/promoConstants';

// Action: Fetch promos
export const fetchPromos = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PROMOS_REQUEST });
    
    const response = await axios.get(`${BASE_URL}/api/promo`); // Adjust the API endpoint according to your backend
    console.log("promos response: " + response)
    dispatch({
      type: FETCH_PROMOS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROMOS_FAILURE,
      payload: error.message,
    });
  }
};


