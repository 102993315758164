import { Link, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineHeart, AiOutlineEye, AiFillHeart } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { addItemsToCart } from "../../redux/actions/cartActions";
import { ProductRating } from "../../components";
import { addItemsToWishlist } from "../../redux/actions/wishlistActions";
import ProductModal from "../../components/common/ProductModal/ProductModal";
import React, { useEffect, useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { FiHeart, FiStar } from 'react-icons/fi';
import { buyNow } from "../../redux/actions/buyAction";

import './shop.scss'
// import Items from "./Items";

const SingleProductMobile = ({ product, gridView }) => {
  const { cartItems } = useSelector((state) => state.cart);
  const [showmodal, setShowModal] = useState(false);
  const token = useSelector((state) => state.userLogin?.userInfo?.access_token);
  const [isMobile, setIsMobile] = useState(false);
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const addOrNot = cartItems?.find((item) => item.product === product._id);
  const dispatch = useDispatch();
  const addToCartHandler = () => {
    dispatch(addItemsToCart(product?._id, 1, addToast, token));
  };



  const { wishlistItems } = useSelector((state) => state.wishlist);

  const addWishlistOrNot = wishlistItems?.find(
    (item) => item.product === product._id
  );

  const addToWishlistHandler = () => {
    dispatch(addItemsToWishlist(product?._id, 1, addToast));
  };

  const [imagesLoaded, setImagesLoaded] = useState(false);
  // const [isMobile, setIsMobile] = useState(false);
  const [prodStatus, setProdStatus] = useState(false);


  // let images = [];
  // useEffect(() => {
  //   if (product.images && product.images.length > 0) {
  //     setImagesLoaded(true);

  //     images = product.images.map((image) => ({
  //       original: image.public_id,
  //       thumbnailD: image.thumbnailDD,
  //     }));
  //   }
  // }, [product.images]);
  console.log("State STT: " + prodStatus);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);

  // Function to handle thumbnailD click
  const handleThumbnailClick = (index) => {
    setActiveThumbnailIndex(index);
  };
  const addToBuy = () => {
    dispatch(buyNow(product?._id));
    console.log("buy data lciked", product?._id)
    navigate(`/shipping/:${product?._id}`);
  };
  //console.log(product, "Products Details", imagesLoaded);

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;

  const productsData = useSelector((state) => state.allProducts);

  const {
    success,
    error: reviewError,
    message,
    loading: reviewLoading,
  } = useSelector((state) => state.newReview);

  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = productsData;


  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    if (product.Stock <= quantity) return;

    const qty = quantity + 1;
    setQuantity(qty);
  };

  const decreaseQuantity = () => {
    if (1 >= quantity) return;

    const qty = quantity - 1;
    setQuantity(qty);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const button = document.querySelector(".AddtoCart");
  // const done = document.querySelector(".done");
  // console.log(button);
  // let added = false;
  // button.addEventListener('click',()=>{
  //   if(added){
  //     done.style.transform = "translate(-110%) skew(-40deg)";
  //     added = false;
  //   }
  //   else{
  //     done.style.transform = "translate(0px)";
  //     added = true;
  //   }

  // });



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set the breakpoint width for mobile devices
    };

    handleResize(); // Check the initial viewport width

    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);
  const cond = product?.images?.length > 0;
  let content = null;
  let contentList = null;
  if (cond) {
    content = <img className="default-img" src={product?.images[0].thumbnailD} alt="" />;
    contentList = <img className="default-img" src={product?.images[0].thumbnailD} alt="" />;
  } else {
    content = <img className="default-img" src={product?.images.thumbnailD} alt="" />
    contentList = <img className="default-img" src={product?.images.thumbnailD} alt="" />;
  }

  console.log(cond, "Prod", product);
  return (
    <>

      {gridView ? (
        <div className={`product-wrap`}>
          <div className="product-img">
            <Link to={`/product/${product._id}`}>
              {content}
            </Link>

            <div className="product-action">
              <div className="pro-same-action pro-cart">
                {product?.Stock && product?.Stock > 0 ? (
                  <button
                    disabled={addOrNot?.quantity > 0}
                    onClick={addToCartHandler}
                    className="cart-btn"
                  >
                    <FiShoppingCart />
                    {addOrNot?.quantity > 0 ? "Added" : "Buy Now"}
                  </button>
                ) : (
                  <button disabled className="active">
                    Out of Stock
                  </button>
                )}
              </div>
              <div className="pro-same-action pro-quickview">
                <button onClick={() => setShowModal(true)} title="Quick View">
                  <AiOutlineEye />
                </button>
              </div>
            </div>
          </div>
          <div className="product-content text-center">

            <h3>
              <Link to={"/product/" + product._id}>{product.name}</Link>
            </h3>

            <div className="product-price">
              <span>&#8377;{product.price}</span>
            </div>

            {/* <div>
              {isMobile ? (
                <div className="product-attributes">
                  {product.attributes?.map((a) => (
                    <span className="attribute">{a.key} - {a.value}</span>

                  ))}
                </div>
              ) : (
                <p className="product-description" dangerouslySetInnerHTML={{ __html: product.description.slice(0, 400) + "...." }} />
              )}
            </div> */}
          </div>
        </div>
      ) : (
        // <div className={`product-wrap product-grid-line-view`}>
        //   <div className="product-img-grid">
        //     <Link to={`/product/${product._id}`}>
        //       <img className="default-img" src={product?.images[0]?.thumbnailD} alt="" />
        //     </Link>
        //   </div>
        //   <div className="product-content">
        //     <h3>
        //       <Link to={"/product/" + product._id}>{product.name}</Link>
        //     </h3>

        //     <div className="product-price">
        //       <span>${product.price}</span>
        //     </div>

        //     {/* <div className="product-rating">
        //       <ProductRating ratingValue={product.ratings} />({product.ratings})
        //     </div> */}

        //     {/* <div className="product-description">
        //       <p>{product.description.slice(0, 100) + "...."}</p>
        //     </div> */}

        //     <div className="product-description"
        //           dangerouslySetInnerHTML={{ __html: product.description.slice(0, 100) + "...." }}
        //         />


        //     <div className="buttons-group">
        //       <div className="buy-now">
        //         {product?.Stock && product?.Stock > 0 ? (
        //           <button
        //             disabled={addOrNot?.quantity > 0}
        //             onClick={addToCartHandler}
        //             className="cart-btn"
        //           >
        //             <FiShoppingCart />
        //             {addOrNot?.quantity > 0 ? "Added" : "Buy Now"}
        //           </button>
        //         ) : (
        //           <button disabled className="active">
        //             Out of Stock
        //           </button>
        //         )}
        //       </div>

        //       <div className="buy-now pro-wishlist">
        //         <button
        //           disabled={addWishlistOrNot?.quantity > 0}
        //           onClick={addToWishlistHandler}
        //           title={
        //             addWishlistOrNot?.quantity > 0
        //               ? "Added to Wishlist"
        //               : "Add to Wishlist"
        //           }
        //         >
        //           {addWishlistOrNot?.quantity > 0 ? (
        //             <AiFillHeart style={{ color: "red" }} />
        //           ) : (
        //             <AiOutlineHeart />
        //           )}
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>

        <Link to={`/product/${product._id}`}>
          <div className={`product-wrap`}>
            <div className="product-img">

              {content}





              <div className="product-content text-center">

                <h3>
                  <Link to={"/product/" + product._id}>{product.shortname} </Link>
                </h3>
                {/* 
                <div className="product-attributes" style={{ textAlign: 'left', marginLeft: "4%" }}>
        
                  {product.attributes?.map((attribute, index) => (
                    <span key={index} className="attribute">
                      <h5><strong>{attribute.key}</strong> - {attribute.value}</h5>
                    </span>
                  ))}
                </div> */}

                <div className="off" style={{ color: "green", textAlign: 'left', marginLeft: "4%" }}>10% Off</div>
                <div className="product-price">

                  <div style={{ textAlign: 'left', marginLeft: "4%" }}><s>&#8377;{(product.price) / 10}</s></div>
                  <span style={{ textAlign: 'left', marginLeft: "4%" }}>&#8377;{product.price}</span>
                </div>

                {/* <div className="product-price">
              <span>&#8377;{product.price}</span>
            </div> */}


                {/* <div className="product-action"> */}
                {/* <div className="product__detail__info__buttons">
                  <div className="product__detail__info__buttons-add">
                    {product?.Stock && product?.Stock > 0 ? (
                      <button
                        disabled={addOrNot?.quantity > 0}
                        onClick={addToCartHandler}
                      >
                        {addOrNot?.quantity > 0 ? "Added In Cart" : "Add To Cart"}
                      </button>
                    ) : (
                      <button disabled>Out of Stock</button>
                    )}
                  </div>
                  <div className="product__detail__info__buttons-buy">
                    {product?.Stock && product?.Stock > 0 ? (
                      <button onClick={addToBuy} title="Buy Now">
                        Buy Now
                      </button>
                    ) : (
                      <button disabled className="active">
                        Out of Stock
                      </button>
                    )}
                  </div>
                </div> */}

                   {/* </div> */}





                         {/* <div className="pro-same-action pro-cart">
                        {product?.Stock && product?.Stock > 0 ? (
                            <Button
                              disabled={addOrNot?.quantity > 0}
                              onClick={addToCartHandler}
                              className="cart-btn buy-btn"
                              title={
                                addOrNot?.quantity > 0 ? "Added to Cart" : "Add to Cart"
                              }
                            >
                          
                              {addOrNot?.quantity > 0 ? "Added" : " Add to Cart"}
                            </Button>
                          ) : (
                            <Button disabled className="active">
                              Out of Stock
                            </Button>
                          )}

                          </div> */}
              </div>
            </div>



            {/* <div>
            {isMobile ? (
              <div className="product-attributes">
                {product.attributes?.map((a) => (
                  <span className="attribute">{a.key} - {a.value} </span>

                ))}
              </div>
            ) : (
              <p className="product-description" dangerouslySetInnerHTML={{ __html: product.description.slice(0, 400) + "...." }} />
            )}
          </div> */}
          </div>
       </Link>
      )}



      {/* quick view modal */}
      <ProductModal
        product={product}
        showmodal={showmodal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default SingleProductMobile;





