import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container,Spinner,Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { fetchBrandProducts } from "../../../redux/actions/productActions";
const BoxComponent = ({ image, text, ID,Brand,Price }) => (
  <div key={ID} className="allProducts__container__item product-wrap" style={{
    width: '30%', // Use a string for the width value
    display: 'flex',
    position: 'relative',
    border: '1px solid #e4e4e4',
    transition: 'all 0.5s ease',
    fontFamily: 'Roboto, sans-serif',
    flexDirection: "column-reverse",
    flexWrap: 'wrap',
    // alignContent: 'space-around',
    // alignItems: 'space-around',
}}>
   <Link to={`/product/${ID}`}>
  <div class="image-container">
  <img src={image} alt="" />
   </div>
 <div className="allProducts__container__item__content" style={{ padding: "4%" }}>
   <h4 style={{ textTransform: 'capitalize',color: "#878787",
 
   fontWeight: "500"}}>{Brand}</h4>
   <Card.Title>
    
       <h3>
         <strong>{text} </strong>
       </h3>
       <div className="product__detial__info__price">
                <span className="product__detail__info__price-count">
                &#8377; {Price}
                </span>
              </div>
 
   </Card.Title>
   <Card.Text>
  
   </Card.Text>

 </div>
 </Link>
</div>
);

const OneBrand = () => {
    const [brand, setBrand] = useState("Samsung");
    const [sort, setSort] = useState("");
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [callback, setCallback] = useState(false);

    const { products, loading, error } = useSelector(
        (state) => state.shopProduct
    );
    const token = useSelector((state) => state.userLogin?.userInfo?.access_token);
    const sx = useSelector((state) => state.userLogin?.userInfo?.user);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchBrandProducts(page, brand, sort, search,token,sx.sn));
    }, [dispatch, page, brand, sort, search,token,sx.sn]);

    

//console.log("Loading products",products);
    return (
        <section className="featured container-div">
        <div className="featured__container">
          <div classname="LR2" style={{marginTop: "6%",
      display: "flex",    marginTop: "6%",
      flexWrap: "nowrap",
      justifyContent: "space-between"}}>
          <h3 className="featured__title">Top Sellers </h3>
            {/* <button className="view-all-button">View All</button> */}
            <Link className="view-all-button" to={`/brandShop/`} >View All</Link>
          </div>
      
          <div className="featured__products">
            {loading ? (
              <Spinner
                style={{ marginLeft: "50%", marginTop: "5%" }}
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : error ? (
              <h2
                style={{
                  color: "#333",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {error}
              </h2>
            ) : (
        <>
         
                {products?.products?.map((product, i) => (
                    <BoxComponent key={i} image={product.images?.thumbnailD || (product.images && product.images[0].thumbnailD)}  text={product.name} ID={product._id} Brand={product.brand} Price={product.price}/>
                    ))}
           
          
        </>
       )}
       </div>
     </div>
   </section>
 );
};

export default OneBrand;
