import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { buyNow } from "../../../../redux/actions/buyAction";
const BuyOrder = () => {
  const navigate = useNavigate();
  const { purchasedProduct } = useSelector((state) => state.buyNow);
  const { shippingInfo, cartItems } = useSelector((state) => state?.cart);
  const { userInfo } = useSelector((state) => state?.userLogin);
  
  console.log(purchasedProduct?.product , ' - ', purchasedProduct?.stock,"on confirm page",purchasedProduct);
  
  const subtotal =1 * purchasedProduct.price;

  const shippingCharges = subtotal < 1000 ? 0 : 100;

  // Calculate the tax amount
const taxAmount = (subtotal * purchasedProduct.tax) / 100;
  // const tax = subtotal;
  // const tax = subtotal * 0.18;

  const totalPrice = subtotal + taxAmount + shippingCharges;


  const address = `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;

  const proceedToPayment = () => {
    const data = {
      subtotal,
      shippingCharges,
      // tax,
      totalPrice,
    };

    sessionStorage.setItem("buyInfo", JSON.stringify(data));
    navigate("/process/buypayment");
  };

  return (
    <section className="container-div section confirm">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Confirm - Order</title>
      </Helmet>

      <div className="confirm__order grid">
        <div className="confirm__order__shipping grid">
          <div className="confirm__order__shipping__area">
            <h3 className="confirm__order__shipping__area-title">
              Shipping Info
            </h3>
            <div className="confirm__order__shipping__area__box">
              <div>
                <p>
                  Name: <span>{userInfo?.user?.name}</span>
                </p>
              </div>
              <div>
                <p>
                  Phone: <span>{shippingInfo?.phoneNo}</span>
                </p>
              </div>
              <div>
                <p>
                  Address: <span> {address}</span>
                </p>
              </div>
            </div>
          </div>
     
          {purchasedProduct?.product && purchasedProduct?.stock > 0 ? (    
          <div className="confirm__order__cartItem">
            <h3 className="confirm__order__shipping__area-title">
              Your buy Now:
            </h3>
            <div className="confirm__order__cartItem__container">
            
                  <div
                    key={purchasedProduct.product}
                    className="confirm__order__cartItem__container__items"
                  >
                    <img src={purchasedProduct.image} alt="Product" />
                    <div className="confirm__order__cartItem__container__items-price">
                      <Link to={`/product/${purchasedProduct.product}`}>{purchasedProduct.name}</Link>{" "}
                      <span>
                        {purchasedProduct.quantity} X {purchasedProduct.price} = &#8377;
                        {purchasedProduct.price * purchasedProduct.quantity}
                      </span>
                    </div>
                  </div>
             
            </div>

          </div>
          ):(<p></p>)}
        </div>

        <div className="confirm__order__summary">
          <h3 className="confirm__order__shipping__area-title">
            Order Summery
          </h3>
          <div className="confirm__order__summary__subtotal">
  <div>
    <p>Subtotal:</p>
    <span>&#8377;{subtotal.toFixed(2)}</span>
  </div>
  <div>
    <p>Shipping Charges:</p>
    <span>&#8377;{shippingCharges}</span>
  </div>
  <div>
    <p>Tax ({purchasedProduct.tax}%):</p>
    <span>&#8377;{taxAmount.toFixed(2)}</span>
  </div>
</div>


          <hr />
          <div className="confirm__order__summary__total">
            <p>Total:</p>
            <span>&#8377;{totalPrice.toFixed(2)}</span>
          </div>

          <div className="confirm__button">
            <button className="button" onClick={proceedToPayment}>
              Proceed To Payment
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyOrder;
