export const FETCH_PROMOS_REQUEST = 'FETCH_PROMOS_REQUEST';
export const FETCH_PROMOS_SUCCESS = 'FETCH_PROMOS_SUCCESS';
export const FETCH_PROMOS_FAILURE = 'FETCH_PROMOS_FAILURE';

export const CREATE_PROMO_REQUEST = 'CREATE_PROMO_REQUEST';
export const CREATE_PROMO_SUCCESS = 'CREATE_PROMO_SUCCESS';
export const CREATE_PROMO_FAILURE = 'CREATE_PROMO_FAILURE';

export const UPDATE_PROMO_REQUEST = 'UPDATE_PROMO_REQUEST';
export const UPDATE_PROMO_SUCCESS = 'UPDATE_PROMO_SUCCESS';
export const UPDATE_PROMO_FAILURE = 'UPDATE_PROMO_FAILURE';

export const DELETE_PROMO_REQUEST = 'DELETE_PROMO_REQUEST';
export const DELETE_PROMO_SUCCESS = 'DELETE_PROMO_SUCCESS';
export const DELETE_PROMO_FAILURE = 'DELETE_PROMO_FAILURE';
