import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Country, State } from "country-state-city";
import { Helmet } from "react-helmet";
import { AiFillHome,AiOutlineUser  } from "react-icons/ai";
import { FaCity } from "react-icons/fa";
import {fetchState} from "../../redux/actions/stateActions";
import {
  MdPinDrop,
  MdOutlinePublic,
  MdTransferWithinAStation,
} from "react-icons/md";

import { BsFillTelephoneFill } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { saveShippingInfo } from "../../redux/actions/cartActions";
import { Link,useParams } from "react-router-dom";
const Shipping = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ID } = useParams();
  const [buyOptions,setBuyOptions] = useState();
  useEffect(() => {
    dispatch(fetchState());
  }, [dispatch]);
  const stateData = useSelector((state) => state.stateData);
  const { states } = stateData;
  const [selectedState, setSelectedState] = useState(null);
  useEffect(() => {
    if (ID) {
      console.log("Got ID ",ID);
      setBuyOptions(ID);
    }else{}
    console.log("Ship IDD", ID);
  }, [ID]);
  const { shippingInfo } = useSelector((state) => state.cart);
  const [name, setName] = useState(shippingInfo.name);
  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const sx = useSelector((state) => state.userLogin?.userInfo?.user);
  const [state, setState] = useState(sx.sn);
  const [country, setCountry] = useState(shippingInfo.country);
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);

  const [latlng, setLatitudeLongitude] = useState(shippingInfo.latlng);
 // const [longitude, setLongitude] = useState(null);

   
 useEffect(() => {
  const getLocation = () => {
    if (navigator.geolocation) {
      console.log(navigator.geolocation ," GPS ",latlng)
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("position cords",position.coords.latitude)
          let ltlng = position.coords.latitude+","+position.coords.longitude;
          setLatitudeLongitude(position.coords.latitude+","+position.coords.longitude);
        }
      );
    } 
  };

  getLocation();
}, []);
  const handleChangeInput = (e) => {
    setState(e.target.value);
  }
  const shippingSubmit = (e) => {
    e.preventDefault();
   
    if (phoneNo.length < 10 || phoneNo.length > 10) {
      alert("Phone Number should be 10 digits Long");
      return;
    }
    dispatch(
      saveShippingInfo({name, address, city, state, country, pinCode, phoneNo,latlng })
    );
    if(buyOptions){
      navigate("/order/buyNow");
    }else{
      navigate("/order/confirm");
    }
    
  };

  return (
    <section className="containter-div section shipping">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shipping</title>
      </Helmet>
      <div className="shipping__container">
        <div className="shipping__box">
          <h2 className="shipping__heading">Shipping Details</h2>

          <form
            className="shipping__form grid"
            encType="multipart/form-data"
            onSubmit={shippingSubmit}
          >
                 <div>
              <AiOutlineUser />
              <input
                type="text"
                name="name"
                placeholder="Customer Name"
                id="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <AiFillHome />
              <input
                type="text"
                name="address"
                placeholder="Address"
                id="address"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div>
              <FaCity />
              <input
                type="text"
                name="city"
                placeholder="City"
                id="city"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div>
              <MdPinDrop />
              <input
                type="number"
                name="pincode"
                placeholder="Pin Code"
                id="pincode"
                required
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
            </div>

            <div>
              <BsFillTelephoneFill />
              <input
                type="number"
                name="phonenumber"
                id="phonenumber"
                placeholder="Phone Number"
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                size="11"
              />
            </div>

              <div>
    
    </div>
     

            <input
              type="submit"
              value="Continue"
              className="button"
              disabled={state ? false : true}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Shipping;
