
import { BASE_URL } from "../../config";
import axios from "axios";
import { BUY_NOW, RESET,SUCCESS } from "../constants/buyConstants"; // Import the BUY_NOW and RESET constants

// export const addItemsToCart =(id, quantity, addToast) => async (dispatch, getState) => {}

export const buyNow = (productId,token)=> async (dispatch) => {
try {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
    const { data } = await axios.get(`${BASE_URL}/api/products/${productId}`,config);

    dispatch({
      type: BUY_NOW,
      payload: {
        product: data._id,
        name: data.name,
        price: data.price,
        tax: data.tax,
        totalprice: data.totalprice,
        seller: data.seller,
        image: data.images?.thumbnailD || (data.images && data.images.length > 0 && data.images[0].thumbnailD),
        stock: data.Stock,
        quantity:1,
      },
    });

} catch (error) {
    console.log(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }

};

// Action to reset the state
export const resetBuyNow = () => (dispatch) => {
  dispatch({ type: RESET });
};