export const FETCH_BANNERS_REQUEST = 'FETCH_BANNERS_REQUEST';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_FAILURE = 'FETCH_BANNERS_FAILURE';

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';

export const UPDATE_BANNER_REQUEST = 'UPDATE_BANNER_REQUEST';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE';

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_FAILURE = 'DELETE_BANNER_FAILURE';
