import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";
import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";
import Hamburger from 'hamburger-react'


const subcategoryImages = {
  Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
  "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
  Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
  SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
  Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
  HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
  Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
  Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
  "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
  Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
   Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
   Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
   Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
   OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
   haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
  // Add more category images as needed
};

const FlyoutMenu = ({ menus }) => {
  // const [currentLink, setCurrentLink] = useState(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null); // Track open submenu index
    const [isMobileView, setIsMobileView] = useState(false);
    const [subMenuOpenIndex, setSubMenuOpenIndex] = useState(null); // Track open submenu index
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
    const [openDesktopSubmenuIndex, setOpenDesktopSubmenuIndex] = useState(null);


   const toggleSubMenu = (index) => {
    // Toggle the submenu for desktop view on hover
    setOpenSubmenuIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // const toggleSubMenu = (index) => {
  
  //       // setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  //       setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
  //     };
      
      const handleMobileSubMenuClick = (index) => {
        // Toggle submenu when clicked
        setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
      };
  //      const handleMobileSubMenuClick = (index) => {
  //   // Add a delay to handle the submenu click
  //   setTimeout(() => {
  //     setOpenSubmenuIndex((prevIndex) =>
  //       prevIndex === index ? null : index
  //     );
  //   }, 100); // Adjust the delay time as needed
  // };
 
  const handleDesktopSubMenuClick = (index) => {
    setOpenDesktopSubmenuIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };




  // console.log("menus", menus[2].items[3]);
      const mobileMenuClass = isMobileMenuOpen ? "mobile-menu-open" : "";

 

  return (
    // <nav className="container" id="mega_menu">
    <nav className={`container ${isMobileView ? "mobile-view" : "desktop-view"}`}  id="mega_menu" >
       <input type="checkbox" id="touch-menu" />
<label htmlFor="touch-menu" className={`mobile-menu ${isMobileView ? "mobile-visible" : "desktop-visible"}`} onClick={toggleMobileMenu} ><strong>  <Hamburger size={30} color="#000" toggled={isMobileMenuOpen} /></strong></label> 
    <img
            src="https://cdn.storehippo.com/s/62fe03157f1fa3a21747b8c4/63181d9166770bad3bd50979/webp/asset-1-1--480x480.png"
            alt="shop" className="D2U"
          />
     
    {/* <div className="menu"> */}
    {/* <div className={`menu ${isMobileMenuOpen ? "open" : ""}`}> */}
    <div className={`menu ${isMobileView ? "open" : ""} menu-mobile-scroll ${mobileMenuClass}`}>
      <ul className="menu-main" id="menu">
      {/* <ul className={`menu-main ${isMobileMenuOpen ? "open" : ""}`}> */}
        {menus?.map((item, index) => (
          <li
            key={index}
            // className={`drop-down ${openSubMenuIndex  === index ? "active" : ""}`}
              className={`drop-down ${
                (isMobileView && openSubMenuIndex === index) ||
                (!isMobileView && openDesktopSubmenuIndex === index)
                  ? "active"
                  : ""
              }`}
            onClick={() => {
              if (isMobileView) {
                handleMobileSubMenuClick(index); // Toggle submenu for mobile view
              }
            }}
            onMouseEnter={() => {
              if (!isMobileView) {
                toggleSubMenu(index); // Toggle submenu for desktop view on hover
              }
            }}
            onMouseLeave={() => {
              if (!isMobileView) {
                toggleSubMenu (index); // Close submenu for desktop view on hover out
              }
            }}
          >
            {/* Check if the current item is not "Home" or if it's not a mobile view */}
    {!isMobileView || item.item !== "Home1" ? (
      <>
             <input type="checkbox" id={`touch-menu-${index}`} className="touch-menu"
            //  checked={openSubmenuIndex === index}
               checked={
                  (isMobileView && openSubMenuIndex === index) ||
                  (!isMobileView && openDesktopSubmenuIndex === index)
                }
                onChange={() => {}} />
                <label htmlFor={`touch-menu${index}`} className="mobile-submenu" id="in"
                onClick={() => {
                  if (!isMobileView) {
                    handleMobileSubMenuClick(index);
                  }
                   else {
                    handleDesktopSubMenuClick(index);
                  }
                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                  if (!isMobileView) {
                    handleMobileSubMenuClick(index);
                  } 
                }}
                onTouchEnd={(e) => {
                    e.preventDefault();
                }}
                  data-home={item.item === "Home1" ? "true" : "false"}>
       </label>
       </>
    ) : null}

            <Link to={item.path}>
              <h3 className="drop">
              <li className="drop-down" >
                <span  style={{ fontWeight: "bold", marginTop:"18px" }}>{item.item}</span>
                </li>
              </h3>
            </Link>

            {/* {item.submenu && openSubmenuIndex === index && ( */}
            {item.submenu && (openSubmenuIndex === index || subMenuOpenIndex === index || isMobileView ) && (
              <div className="ruby-grid ruby-grid-lined">
                <div className="menu-sub" style={{ animation: "slideDown 0.2s ease-in-out" }}>
                {/* <div className="submenu menu-column-4"> */}
                   {/* <div
                  className={`submenu menu-column-4 ${openSubMenuIndex === index ? "open" : ""}`}
                > */}
                     <div
                  className={`submenu menu-column-4 ${
                    (isMobileView && openSubMenuIndex === index) ||
                    (!isMobileView && openDesktopSubmenuIndex === index)
                      ? "open"
                      : ""
                  }`}
                >
                  <div className="menu-col-1">
                    <ul className="submenu-items">
                      {item?.submenu?.map((subitem, subindex) => {
                        // const SubcategoryIcon = subcategoryIcons[subitem.item];
                        const checkboxId = `touch-submenu-${index}-${subindex}`;
                           const labelId = `label-submenu-${index}-${subindex}`;
                        const SubcategoryImage = subcategoryImages[subitem.item];
                        return (
                          <li key={subindex}>
                            <input type="checkbox" id={checkboxId} className="touch-submenu"  // Hide the submenu checkbox if main menu is open
                            />
                              {/* <label htmlFor={checkboxId} className="mobile-submenu" ></label> */}
                              <label
                                  htmlFor={checkboxId}
                                  className={`mobile-submenu ${isMobileView ? "mobile-visible" : "desktop-visible"}`}
                                  id={labelId}
                                  ></label>
                            <Link to={subitem.path}>
                            {SubcategoryImage && (
                                <img
                                  src={SubcategoryImage}
                                  className="subcategory_image"
                                  style={{
                                    width: "10%",
                                    height: "10%",
                                    marginRight: "1%"
                                  }}
                                />
                              )} 
                              {/* {SubcategoryIcon && <SubcategoryIcon />} */}
                              <a href="#">
                              <span style={{ fontWeight: "bold" }}>
                                {subitem.item}
                              </span>
                              </a>
                            </Link>
                            { subitem.dropdown && (
                              <div className="submenu"  style={{ animation: 'slideDown 0.2s ease-in-out' }}>
                              <ul className="menu-col-2">
                                {subitem.dropdown.map(
                                  (dropdownItem, dropdownIndex) => (
                                    <li key={dropdownIndex}>
                                      <a href={dropdownItem.path}>
                                        {dropdownItem.item}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              </div>
            )}
          </li>
        ))}
      </ul>
      </div>
    </nav>
  );
};


export default FlyoutMenu;


























// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";
// import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";


// const subcategoryImages = {
//   Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
//   "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
//   Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
//   SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
//   Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
//   HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
//   Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
//   Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
//   "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
//   Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
//    Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
//    Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
//    Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
//    OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
//    haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
//   // Add more category images as needed
// };

// const FlyoutMenu = ({ menus }) => {
//   const [currentLink, setCurrentLink] = useState(null);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//     const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null); // Track open submenu index
//     const [isMobileView, setIsMobileView] = useState(false);
//     const [subMenuOpenIndex, setSubMenuOpenIndex] = useState(null); // Track open submenu index
//     const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);

    
  
//   // const handleMenuEnter = (index) => {
//   //   setCurrentLink(index);
//   // };

//   // const handleMenuLeave = () => {
//   //   setCurrentLink(null);
//   // };


//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const toggleSubMenu = (index) => {
  
//         // setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
//         setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
//       };


  
//       // const handleMobileSubMenuClick = (index) => {
//       //   // Handle mobile submenu click to open/close
//       //   if (openSubmenuIndex === index) {
//       //     setOpenSubmenuIndex(null); // Close the submenu
//       //   } else {
//       //     setOpenSubmenuIndex(index); // Open the submenu
//       //   }
//       // };
      
//       const handleMobileSubMenuClick = (index) => {
//         // Toggle submenu when clicked
//         setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
//       };
    
    
//       const mobileMenuClass = isMobileMenuOpen ? "mobile-menu-open" : "";
 

//   return (
//     // <nav className="container" id="mega_menu">
//     <nav className={`container ${isMobileView ? "mobile-view" : "desktop-view"}`}  id="mega_menu" >
//        <input type="checkbox" id="touch-menu" />
//     {/* <label htmlFor="touch-menu" className="mobile-menu"></label> */}
//     <label htmlFor="touch-menu" className={`mobile-menu ${isMobileView ? "mobile-visible" : "desktop-visible"}`} onClick={toggleMobileMenu} ><strong><img src="https://cdn-icons-png.flaticon.com/128/7710/7710488.png"></img></strong></label>
//     <img
//             src="https://cdn.storehippo.com/s/62fe03157f1fa3a21747b8c4/63181d9166770bad3bd50979/webp/asset-1-1--480x480.png"
//             alt="shop" className="D2U"
//           />
     
//     {/* <div className="menu"> */}
//     {/* <div className={`menu ${isMobileMenuOpen ? "open" : ""}`}> */}
//     <div className={`menu ${isMobileView ? "open" : ""} menu-mobile-scroll ${mobileMenuClass}`}>
//       <ul className="menu-main">
//       {/* <ul className={`menu-main ${isMobileMenuOpen ? "open" : ""}`}> */}
//         {menus?.map((item, index) => (
//           <li
//             key={index}
//             className={`drop-down ${openSubMenuIndex  === index ? "active" : ""}`}
//             // onMouseEnter={() => toggleSubMenu(index)}
//             // onMouseLeave={() => toggleSubMenu(index)}
//             onClick={() => {
//               if (isMobileView) {
//                 handleMobileSubMenuClick(index); // Toggle submenu for mobile view
//               }
//             }}
//             onMouseEnter={() => {
//               if (!isMobileView) {
//                 toggleSubMenu(index); // Toggle submenu for desktop view on hover
//               }
//             }}
//             onMouseLeave={() => {
//               if (!isMobileView) {
//                 toggleSubMenu(index); // Close submenu for desktop view on hover out
//               }
//             }}
//           >
//             {/* Check if the current item is not "Home" or if it's not a mobile view */}
//     {!isMobileView || item.item !== "Home1" ? (
//       <>
//              <input type="checkbox" id={`touch-menu-${index}`} className="touch-menu"  checked={openSubmenuIndex === index}
//                 onChange={() => {}} />
//                 <label htmlFor={`touch-menu${index}`} className="mobile-submenu" id="in"
//                 //  onClick={() => toggleSubMenu(index)}
//                 onClick={() => handleMobileSubMenuClick(index)}
//                   data-home={item.item === "Home1" ? "true" : "false"}
//                   onTouchStart={(e) => {
//                     e.preventDefault();
//                     handleMobileSubMenuClick(index);
//                   }}
//                   onTouchEnd={(e) => {
//                       e.preventDefault();
//                   }}
//                   >
//        </label>
//        </>
//     ) : null}

//             <Link to={item.path}>
//               <h3 className="drop">
//               <li className="drop-down" >
//                 <span  style={{ fontWeight: "bold", marginTop:"18px" }}>{item.item}</span>
//                 </li>
//               </h3>
//             </Link>

//             {/* {item.submenu && openSubmenuIndex === index && ( */}
//             {item.submenu && (openSubmenuIndex === index || subMenuOpenIndex === index || isMobileView) && (
//               <div className="ruby-grid ruby-grid-lined">
//                 <div className="menu-sub" style={{ animation: "slideDown 0.2s ease-in-out" }}>
//                 {/* <div className="submenu menu-column-4"> */}
//                    <div
//                   className={`submenu menu-column-4 ${openSubMenuIndex === index ? "open" : ""}`}
//                 >
//                   <div className="menu-col-1">
//                     <ul className="submenu-items">
//                       {item?.submenu?.map((subitem, subindex) => {
//                         // const SubcategoryIcon = subcategoryIcons[subitem.item];
//                         const checkboxId = `touch-submenu-${index}-${subindex}`;
//                            const labelId = `label-submenu-${index}-${subindex}`;
//                         const SubcategoryImage = subcategoryImages[subitem.item];
//                         return (
//                           <li key={subindex}>
//                             <input type="checkbox" id={checkboxId} className="touch-submenu"  // Hide the submenu checkbox if main menu is open
//                             />
//                               {/* <label htmlFor={checkboxId} className="mobile-submenu" ></label> */}
//                               <label
//                                   htmlFor={checkboxId}
//                                   className={`mobile-submenu ${isMobileView ? "mobile-visible" : "desktop-visible"}`}
//                                   id={labelId} ></label>
//                             <Link to={subitem.path}>
//                             {SubcategoryImage && (
//                                 <img
//                                   src={SubcategoryImage}
//                                   className="subcategory_image"
//                                   style={{
//                                     width: "10%",
//                                     height: "10%",
//                                     marginRight: "1%"
//                                   }}
//                                 />
//                               )} 
//                               {/* {SubcategoryIcon && <SubcategoryIcon />} */}
//                               <a href="#">
//                               <span style={{ fontWeight: "bold" }}>
//                                 {subitem.item}
//                               </span>
//                               </a>
//                             </Link>
//                             { subitem.dropdown && (
//                               <div className="submenu"  style={{ animation: 'slideDown 0.2s ease-in-out' }}>
//                               <ul className="menu-col-2">
//                                 {subitem.dropdown.map(
//                                   (dropdownItem, dropdownIndex) => (
//                                     <li key={dropdownIndex}>
//                                       <a href={dropdownItem.path}>
//                                         {dropdownItem.item}
//                                       </a>
//                                     </li>
//                                   )
//                                 )}
//                               </ul>
//                               </div>
//                             )}
//                           </li>
//                         );
//                       })}
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//               </div>
//             )}
//           </li>
//         ))}
//       </ul>
//       </div>
//     </nav>
//   );
// };


// export default FlyoutMenu;


//<--------------------------------------------------------->

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";
// import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";
// import Hamburger from 'hamburger-react'


// const subcategoryImages = {
//   Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
//   "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
//   Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
//   SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
//   Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
//   HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
//   Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
//   Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
//   "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
//   Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
//    Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
//    Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
//    Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
//    OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
//    haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
//   // Add more category images as needed
// };

// const FlyoutMenu = ({ menus }) => {
//   // const [currentLink, setCurrentLink] = useState(null);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//     const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null); // Track open submenu index
//     const [isMobileView, setIsMobileView] = useState(false);
//     const [subMenuOpenIndex, setSubMenuOpenIndex] = useState(null); // Track open submenu index
//     const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
//     const [openDesktopSubmenuIndex, setOpenDesktopSubmenuIndex] = useState(null);


//    const toggleSubMenu = (index) => {
//     // Toggle the submenu for desktop view on hover
//     setOpenSubmenuIndex((prevIndex) =>
//       prevIndex === index ? null : index
//     );
//   };


//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   // const toggleSubMenu = (index) => {
  
//   //       // setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
//   //       setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
//   //     };
      
//       // const handleMobileSubMenuClick = (index) => {
//       //   // Toggle submenu when clicked
//       //   setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
//       // };
//        const handleMobileSubMenuClick = (index) => {
//     // Add a delay to handle the submenu click
//     setTimeout(() => {
//       setOpenSubmenuIndex((prevIndex) =>
//         prevIndex === index ? null : index
//       );
//     }, 100); // Adjust the delay time as needed
//   };
 
//   const handleDesktopSubMenuClick = (index) => {
//     setOpenDesktopSubmenuIndex((prevIndex) =>
//       prevIndex === index ? null : index
//     );
//   };




//   // console.log("menus", menus[2].items[3]);
//       const mobileMenuClass = isMobileMenuOpen ? "mobile-menu-open" : "";

 

//   return (
//     // <nav className="container" id="mega_menu">
//     <nav className={`container ${isMobileView ? "mobile-view" : "desktop-view"}`}  id="mega_menu" >
//        <input type="checkbox" id="touch-menu" />
// <label htmlFor="touch-menu" className={`mobile-menu ${isMobileView ? "mobile-visible" : "desktop-visible"}`} onClick={toggleMobileMenu} ><strong>  <Hamburger size={30} color="#000" toggled={isMobileMenuOpen} /></strong></label> 
//     <img
//             src="https://cdn.storehippo.com/s/62fe03157f1fa3a21747b8c4/63181d9166770bad3bd50979/webp/asset-1-1--480x480.png"
//             alt="shop" className="D2U"
//           />
     
//     {/* <div className="menu"> */}
//     {/* <div className={`menu ${isMobileMenuOpen ? "open" : ""}`}> */}
//     <div className={`menu ${isMobileView ? "open" : ""} menu-mobile-scroll ${mobileMenuClass}`}>
//       <ul className="menu-main" id="menu">
//       {/* <ul className={`menu-main ${isMobileMenuOpen ? "open" : ""}`}> */}
//         {menus?.map((item, index) => (
//           <li
//             key={index}
//             // className={`drop-down ${openSubMenuIndex  === index ? "active" : ""}`}
//               className={`drop-down ${
//                 (isMobileView && openSubMenuIndex === index) ||
//                 (!isMobileView && openDesktopSubmenuIndex === index)
//                   ? "active"
//                   : ""
//               }`}
//             onClick={() => {
//               if (isMobileView) {
//                 handleMobileSubMenuClick(index); // Toggle submenu for mobile view
//               }
//             }}
//             onMouseEnter={() => {
//               if (!isMobileView) {
//                 toggleSubMenu(index); // Toggle submenu for desktop view on hover
//               }
//             }}
//             onMouseLeave={() => {
//               if (!isMobileView) {
//                 toggleSubMenu (index); // Close submenu for desktop view on hover out
//               }
//             }}
//           >
//             {/* Check if the current item is not "Home" or if it's not a mobile view */}
//     {!isMobileView || item.item !== "Home1" ? (
//       <>
//              <input type="checkbox" id={`touch-menu-${index}`} className="touch-menu"
//             //  checked={openSubmenuIndex === index}
//                checked={
//                   (isMobileView && openSubMenuIndex === index) ||
//                   (!isMobileView && openDesktopSubmenuIndex === index)
//                 }
//                 onChange={() => {}} />
//                 <label htmlFor={`touch-menu${index}`} className="mobile-submenu" id="in"
//                 onClick={() => {
//                   if (!isMobileView) {
//                     handleMobileSubMenuClick(index);
//                   }
//                    else {
//                     handleDesktopSubMenuClick(index);
//                   }
//                 }}
//                 onTouchStart={(e) => {
//                   e.preventDefault();
//                   if (!isMobileView) {
//                     handleMobileSubMenuClick(index);
//                   } 
//                 }}
//                 onTouchEnd={(e) => {
//                     e.preventDefault();
//                 }}
//                   data-home={item.item === "Home1" ? "true" : "false"}>
//        </label>
//        </>
//     ) : null}

//             <Link to={item.path}>
//               <h3 className="drop">
//               <li className="drop-down" >
//                 <span  style={{ fontWeight: "bold", marginTop:"18px" }}>{item.item}</span>
//                 </li>
//               </h3>
//             </Link>

//             {/* {item.submenu && openSubmenuIndex === index && ( */}
//             {item.submenu && (openSubmenuIndex === index || subMenuOpenIndex === index || isMobileView ) && (
//               <div className="ruby-grid ruby-grid-lined">
//                 <div className="menu-sub" style={{ animation: "slideDown 0.2s ease-in-out" }}>
//                 {/* <div className="submenu menu-column-4"> */}
//                    {/* <div
//                   className={`submenu menu-column-4 ${openSubMenuIndex === index ? "open" : ""}`}
//                 > */}
//                      <div
//                   className={`submenu menu-column-4 ${
//                     (isMobileView && openSubMenuIndex === index) ||
//                     (!isMobileView && openDesktopSubmenuIndex === index)
//                       ? "open"
//                       : ""
//                   }`}
//                 >
//                   <div className="menu-col-1">
//                     <ul className="submenu-items">
//                       {item?.submenu?.map((subitem, subindex) => {
//                         // const SubcategoryIcon = subcategoryIcons[subitem.item];
//                         const checkboxId = `touch-submenu-${index}-${subindex}`;
//                            const labelId = `label-submenu-${index}-${subindex}`;
//                         const SubcategoryImage = subcategoryImages[subitem.item];
//                         return (
//                           <li key={subindex}>
//                             <input type="checkbox" id={checkboxId} className="touch-submenu"  // Hide the submenu checkbox if main menu is open
//                             />
//                               {/* <label htmlFor={checkboxId} className="mobile-submenu" ></label> */}
//                               <label
//                                   htmlFor={checkboxId}
//                                   className={`mobile-submenu ${isMobileView ? "mobile-visible" : "desktop-visible"}`}
//                                   id={labelId}
//                                   ></label>
//                             <Link to={subitem.path}>
//                             {SubcategoryImage && (
//                                 <img
//                                   src={SubcategoryImage}
//                                   className="subcategory_image"
//                                   style={{
//                                     width: "10%",
//                                     height: "10%",
//                                     marginRight: "1%"
//                                   }}
//                                 />
//                               )} 
//                               {/* {SubcategoryIcon && <SubcategoryIcon />} */}
//                               <a href="#">
//                               <span style={{ fontWeight: "bold" }}>
//                                 {subitem.item}
//                               </span>
//                               </a>
//                             </Link>
//                             { subitem.dropdown && (
//                               <div className="submenu"  style={{ animation: 'slideDown 0.2s ease-in-out' }}>
//                               <ul className="menu-col-2">
//                                 {subitem.dropdown.map(
//                                   (dropdownItem, dropdownIndex) => (
//                                     <li key={dropdownIndex}>
//                                       <a href={dropdownItem.path}>
//                                         {dropdownItem.item}
//                                       </a>
//                                     </li>
//                                   )
//                                 )}
//                               </ul>
//                               </div>
//                             )}
//                           </li>
//                         );
//                       })}
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//               </div>
//             )}
//           </li>
//         ))}
//       </ul>
//       </div>
//     </nav>
//   );
// };


// export default FlyoutMenu;















































































//MAIN MAIN NAVBAR END POPPINS------------------------------------------------------------------------------->

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";
// import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";
// import Hamburger from 'hamburger-react'


// const subcategoryImages = {
//   Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
//   "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
//   Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
//   SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
//   Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
//   HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
//   Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
//   Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
//   "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
//   Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
//    Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
//    Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
//    Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
//    OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
//    haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
//   // Add more category images as needed
// };

// const FlyoutMenu = ({ menus }) => {
//   // const [currentLink, setCurrentLink] = useState(null);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//     const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null); // Track open submenu index
//     const [isMobileView, setIsMobileView] = useState(false);
//     const [subMenuOpenIndex, setSubMenuOpenIndex] = useState(null); // Track open submenu index
//     const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
//     const [openDesktopSubmenuIndex, setOpenDesktopSubmenuIndex] = useState(null);


//   //  const toggleSubMenu = (index) => {
//   //   // Toggle the submenu for desktop view on hover
//   //   setOpenSubmenuIndex((prevIndex) =>
//   //     prevIndex === index ? null : index
//   //   );
//   // };

//   const toggleOuterSubMenu = (index) => {
//         // Toggle the outer submenu for desktop view on hover
//         setOpenSubmenuIndex((prevIndex) =>
//           prevIndex === index ? null : index
//         );
//       };
    
//       const toggleInnerSubMenu = (index) => {
//         // Toggle the inner submenu for desktop view on hover
//         setOpenSubmenuIndex((prevIndex) =>
//           prevIndex === index ? null : index
//         );
//       };
    




//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   // const toggleSubMenu = (index) => {
//   //       setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
//   //     };
      
//       const handleMobileSubMenuClick = (index) => {
//         // Toggle submenu when clicked
//         setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
//       };
 
//   const handleDesktopSubMenuClick = (index) => {
//     setOpenDesktopSubmenuIndex((prevIndex) =>
//       prevIndex === index ? null : index
//     );
//   };


//   // console.log("menus", menus[2].items[3]);
//       const mobileMenuClass = isMobileMenuOpen ? "mobile-menu-open" : "";

 

//   return (
//     // <nav className="container" id="mega_menu">
//     <nav className={`container ${isMobileView ? "mobile-view" : "desktop-view"}`}  id="mega_menu" >
//        <input type="checkbox" id="touch-menu" />
// <label htmlFor="touch-menu" className={`mobile-menu ${isMobileView ? "mobile-visible" : "desktop-visible"}`} onClick={toggleMobileMenu} ><strong>  <Hamburger size={30} color="#000" toggled={isMobileMenuOpen} /></strong></label> 
//     <img
//             src="https://cdn.storehippo.com/s/62fe03157f1fa3a21747b8c4/63181d9166770bad3bd50979/webp/asset-1-1--480x480.png"
//             alt="shop" className="D2U"
//           />
     
//     {/* <div className="menu"> */}
//     {/* <div className={`menu ${isMobileMenuOpen ? "open" : ""}`}> */}
//     <div className={`menu ${isMobileView ? "open" : ""} menu-mobile-scroll ${mobileMenuClass}`}>
//       <ul className="menu-main" id="menu">
//       {/* <ul className={`menu-main ${isMobileMenuOpen ? "open" : ""}`}> */}
//         {menus?.map((item, index) => (
//           <li
//             key={index}
//             // className={`drop-down ${openSubMenuIndex  === index ? "active" : ""}`}
//               className={`drop-down ${
//                 (isMobileView && openSubMenuIndex === index) ||
//                 (!isMobileView && openSubmenuIndex === index)
//                   ? "active"
//                   : ""
//               }`}
//             onClick={() => {
//               if (isMobileView) {
//                 handleMobileSubMenuClick(index); // Toggle submenu for mobile view
//               } else {
//                 toggleOuterSubMenu(index);
//               }
//             }}
//             onMouseEnter={() => {
//               if (!isMobileView) {
//                 toggleOuterSubMenu(index); // Toggle submenu for desktop view on hover
//               }
//             }}
//             onMouseLeave={() => {
//               if (!isMobileView) {
//                 toggleOuterSubMenu (index); // Close submenu for desktop view on hover out
//               }
//             }}
//           >
//             {/* Check if the current item is not "Home" or if it's not a mobile view */}
//     {!isMobileView || item.item !== "Home1" ? (
//       <>
//              <input type="checkbox" id={`touch-menu-${index}`} className="touch-menu"
//             //  checked={openSubmenuIndex === index}
//                checked={
//                   (isMobileView && openSubMenuIndex === index) ||
//                   (!isMobileView && openDesktopSubmenuIndex === index)
//                 }
//                 onChange={() => {}} />
//                 <label htmlFor={`touch-menu${index}`} className="mobile-submenu" id="in"
//                 onClick={() => {
//                   if (!isMobileView) {
//                     toggleOuterSubMenu(index);
//                   }
//                    else {
//                     handleMobileSubMenuClick(index);
//                   }
//                 }}
//                 onTouchStart={(e) => {
//                if (!isMobileView) {
//                   e.preventDefault();
//                   handleMobileSubMenuClick(index);
//                 } else {
//                   toggleOuterSubMenu(index)
//                 }
//                 }}
//                 onTouchEnd={(e) => {
//                     e.preventDefault();
//                 }}
//                   data-home={item.item === "Home1" ? "true" : "false"}>
//        </label>
//        </>
//     ) : null}

//             <Link to={item.path}>
//               <h3 className="drop">
//               <li className="drop-down" >
//                 <span  style={{ fontWeight: "bold", marginTop:"18px" }}>{item.item}</span>
//                 </li>
//               </h3>
//             </Link>

//             {/* {item.submenu && openSubmenuIndex === index && ( */}
//             {item.submenu && (openSubmenuIndex === index || subMenuOpenIndex === index || isMobileView ) && (
//               <div className="ruby-grid ruby-grid-lined">
//                 <div className="menu-sub" style={{ animation: "slideDown 0.2s ease-in-out" }}>
//                 {/* <div className="submenu menu-column-4"> */}
//                    {/* <div
//                   className={`submenu menu-column-4 ${openSubMenuIndex === index ? "open" : ""}`}
//                 > */}
//                      <div
//                   className={`submenu menu-column-4 ${
//                     (isMobileView && openSubMenuIndex === index) ||
//                     (!isMobileView && openSubmenuIndex === index)
//                       ? "open"
//                       : ""
//                   }`}
//                 >
//                   <div className="menu-col-1">
//                     <ul className="submenu-items">
//                       {item?.submenu?.map((subitem, subindex) => {
//                         // const SubcategoryIcon = subcategoryIcons[subitem.item];
//                         const checkboxId = `touch-submenu-${index}-${subindex}`;
//                            const labelId = `label-submenu-${index}-${subindex}`;
//                         const SubcategoryImage = subcategoryImages[subitem.item];
//                         return (
//                           <li key={subindex}
//                           className={`drop-down ${
//                                 (isMobileView && openDesktopSubmenuIndex === subindex) ||
//                                 (!isMobileView && openDesktopSubmenuIndex === subindex)
//                                   ? "active"                                  : ""
//                               }`}
//                            onClick={() => {
//                                      if (isMobileView) {
//                                        handleMobileSubMenuClick(subindex); // Toggle inner submenu for mobile view
//                                      }
//                                    }}
//                                    onMouseEnter={() => {
//                                      if (!isMobileView) {
//                                        toggleInnerSubMenu(subindex); // Toggle inner submenu for desktop view on hover
//                                      }                                   }}
//                                    onMouseLeave={() => {
//                                      if (!isMobileView) {
//                                        toggleInnerSubMenu(subindex); // Close inner submenu for desktop view on hover out
//                                      }
//                                    }}

//                           >
//                             <input type="checkbox" id={checkboxId} className="touch-submenu"  // Hide the submenu checkbox if main menu is open
//                             checked={openDesktopSubmenuIndex === subindex}
//                           onChange={() => {}}
//                             />
//                               {/* <label htmlFor={checkboxId} className="mobile-submenu" ></label> */}
//                               <label
//                                   htmlFor={checkboxId}
//                                   className={`mobile-submenu ${isMobileView ? "mobile-visible" : "desktop-visible"}`}
//                                   id={labelId}
//                                   onClick={() => {
//                                      handleMobileSubMenuClick(subindex);
//                                    }}
//                                    onTouchStart={(e) => {
//                                      e.preventDefault();
//                                      handleMobileSubMenuClick(subindex);
//                                    }}
//                                    onTouchEnd={(e) => {
//                                      e.preventDefault();
//                                    }}
//                                   ></label>
//                             <Link to={subitem.path}>
//                             {SubcategoryImage && (
//                                 <img
//                                   src={SubcategoryImage}
//                                   className="subcategory_image"
//                                   style={{
//                                     width: "10%",
//                                     height: "10%",
//                                     marginRight: "1%"
//                                   }}
//                                 />
//                               )} 
//                               {/* {SubcategoryIcon && <SubcategoryIcon />} */}
//                               <a href="#">
//                               <span style={{ fontWeight: "bold" }}>
//                                 {subitem.item}
//                               </span>
//                               </a>
//                             </Link>
//                             { subitem.dropdown && (
//                               <div className="submenu"  style={{ animation: 'slideDown 0.2s ease-in-out' }}>
//                               <ul className="menu-col-2">
//                                 {subitem.dropdown.map(
//                                   (dropdownItem, dropdownIndex) => (
//                                     <li key={dropdownIndex}>
//                                       <a href={dropdownItem.path}>
//                                         {dropdownItem.item}
//                                       </a>
//                                     </li>
//                                   )
//                                 )}
//                               </ul>
//                               </div>
//                             )}
//                           </li>
//                         );
//                       })}
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//               </div>
//             )}
//           </li>
//         ))}
//       </ul>
//       </div>
//     </nav>
//   );
// };


// export default FlyoutMenu;

//--------=====Backup===================------------------------------------->


// import React, { useState } from "react";
// import { Link } from "react-router-dom";
//  import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";
//  import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";
// import Hamburger from 'hamburger-react'

// const subcategoryImages = {
//   Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
//   "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
//   Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
//   SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
//   Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
//   HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
//   Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
//   Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
//   "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
//   Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
//    Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
//    Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
//    Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
//    OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
//    haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
//   // Add more category images as needed
// };

// const FlyoutMenu = ({ menus }) => {
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [openOuterSubmenuIndex, setOpenOuterSubmenuIndex] = useState(null); // Track open outer submenu index
//   const [openInnerSubmenuIndex, setOpenInnerSubmenuIndex] = useState(null); // Track open inner submenu index
//   const [isMobileView, setIsMobileView] = useState(false);

//   const toggleOuterSubMenu = (index) => {
//     // Toggle the outer submenu for desktop view on hover
//     setOpenOuterSubmenuIndex((prevIndex) =>
//       prevIndex === index ? null : index
//     );
//   };

//   const toggleInnerSubMenu = (index) => {
//     // Toggle the inner submenu for desktop view on hover
//     setOpenInnerSubmenuIndex((prevIndex) =>
//       prevIndex === index ? null : index
//     );
//   };

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const toggleMobileOuterSubMenu = (index) => {
//     // Toggle the outer submenu for mobile view with a single click
//     if (openOuterSubmenuIndex === index) {
//       setOpenOuterSubmenuIndex(null);
//     } else {
//       setOpenOuterSubmenuIndex(index);
//     }
//   };

//   const toggleMobileInnerSubMenu = (index) => {
//     // Toggle the inner submenu for mobile view with a single click
//     if (openInnerSubmenuIndex === index) {
//       setOpenInnerSubmenuIndex(null);
//     } else {
//       setOpenInnerSubmenuIndex(index);
//     }
//   };

//   const mobileMenuClass = isMobileMenuOpen ? "mobile-menu-open" : "";

//   return (
//     <nav className={`container ${isMobileView ? "mobile-view" : "desktop-view"}`} id="mega_menu">
//       <input type="checkbox" id="touch-menu" />
//       <label htmlFor="touch-menu" className={`mobile-menu ${isMobileView ? "mobile-visible" : "desktop-visible"}`} onClick={toggleMobileMenu}>
//         <strong>Hamburger</strong>
//       </label>
//       <img
//         src="https://cdn.storehippo.com/s/62fe03157f1fa3a21747b8c4/63181d9166770bad3bd50979/webp/asset-1-1--480x480.png"
//         alt="shop"
//         className="D2U"
//       />
//       <div className={`menu ${isMobileView ? "open" : ""} menu-mobile-scroll ${mobileMenuClass}`}>
//         <ul className="menu-main" id="menu">
//           {menus?.map((item, index) => (
//             <li
//               key={index}
//               className={`drop-down ${
//                 (isMobileView && openOuterSubmenuIndex === index) ||
//                 (!isMobileView && openOuterSubmenuIndex === index)
//                   ? "active"
//                   : ""
//               }`}
//               onClick={() => {
//                 if (isMobileView) {
//                   toggleMobileOuterSubMenu(index); // Toggle outer submenu for mobile view
//                 } else {
//                   toggleOuterSubMenu(index); // Toggle outer submenu for desktop view on click
//                 } 
//               }}
//               onMouseEnter={() => {
//                 if (!isMobileView) {
//                   toggleOuterSubMenu(index); // Toggle outer submenu for desktop view on hover
//                 }
//               }}
//               onMouseLeave={() => {
//                 if (!isMobileView) {
//                   toggleOuterSubMenu(index); // Close outer submenu for desktop view on hover out
//                 }
//               }}
//             >
//               {(!isMobileView || item.item !== "Home1") && (
//                 <>
//                   <input
//                     type="checkbox"
//                     id={`touch-menu-${index}`}
//                     className="touch-menu"
//                     checked={openOuterSubmenuIndex === index}
//                     onChange={() => {}}
//                   />
//                   <label
//                     htmlFor={`touch-menu-${index}`}
//                     className="mobile-submenu"
//                     id="in"
//                     onClick={() => {
//                       if (!isMobileView) {
//                         toggleOuterSubMenu(index);
//                       } else {
//                         toggleMobileOuterSubMenu(index);
//                       }
//                     }}
//                     onTouchStart={(e) => {
//                       e.preventDefault();
//                       if (!isMobileView) {
//                         toggleOuterSubMenu(index);
//                       } else {
//                         toggleMobileOuterSubMenu(index);
//                       }
//                     }}
//                     onTouchEnd={(e) => {
//                       e.preventDefault();
//                     }}
//                     data-home={item.item === "Home1" ? "true" : "false"}
//                   ></label>
//                 </>
//               )}

//               <Link to={item.path}>
//                 <h3 className="drop">
//                   <li className="drop-down">
//                     <span style={{ fontWeight: "bold", marginTop: "18px" }}>{item.item}</span>
//                   </li>
//                 </h3>
//               </Link>

//               {item.submenu && (
//                 <div className="ruby-grid ruby-grid-lined">
//                   <div className="menu-sub" style={{ animation: "slideDown 0.2s ease-in-out" }}>
//                     <div
//                       className={`submenu menu-column-4 ${
//                         (isMobileView && openOuterSubmenuIndex === index) ||
//                         (!isMobileView && openOuterSubmenuIndex === index)
//                           ? "open"
//                           : ""
//                       }`}
//                     >
//                       <div className="menu-col-1">
//                         <ul className="submenu-items">
//                           {item?.submenu?.map((subitem, subindex) => {
//                             const checkboxId = `touch-submenu-${index}-${subindex}`;
//                             const SubcategoryImage = subcategoryImages[subitem.item];
//                             return (
//                               <li key={subindex}
//                                   className={`drop-down ${
//                                     (isMobileView && openInnerSubmenuIndex === subindex) ||
//                                     (!isMobileView && openInnerSubmenuIndex === subindex)
//                                       ? "active"
//                                       : ""
//                                   }`}
//                                   onClick={() => {
//                                     if (isMobileView) {
//                                       toggleMobileInnerSubMenu(subindex); // Toggle inner submenu for mobile view
//                                     }
//                                   }}
//                                   onMouseEnter={() => {
//                                     if (!isMobileView) {
//                                       toggleInnerSubMenu(subindex); // Toggle inner submenu for desktop view on hover
//                                     }
//                                   }}
//                                   onMouseLeave={() => {
//                                     if (!isMobileView) {
//                                       toggleInnerSubMenu(subindex); // Close inner submenu for desktop view on hover out
//                                     }
//                                   }}
//                               >
//                                 <input
//                                   type="checkbox"
//                                   id={checkboxId}
//                                   className="touch-submenu"
//                                   checked={openInnerSubmenuIndex === subindex}
//                                   onChange={() => {}}
//                                 />
//                                 <label
//                                   htmlFor={checkboxId}
//                                   className={`mobile-submenu ${isMobileView ? "mobile-visible" : "desktop-visible"}`}
//                                   onClick={() => {
//                                     toggleMobileInnerSubMenu(subindex);
//                                   }}
//                                   onTouchStart={(e) => {
//                                     e.preventDefault();
//                                     toggleMobileInnerSubMenu(subindex);
//                                   }}
//                                   onTouchEnd={(e) => {
//                                     e.preventDefault();
//                                   }}
//                                 ></label>
//                                 <Link to={subitem.path}>
//                                   {SubcategoryImage && (
//                                     <img
//                                       src={SubcategoryImage}
//                                       className="subcategory_image"
//                                       style={{
//                                         width: "10%",
//                                         height: "10%",
//                                         marginRight: "1%",
//                                       }}
//                                     />
//                                   )}
//                                   <span style={{ fontWeight: "bold" }}>{subitem.item}</span>
//                                 </Link>
//                                 {subitem.dropdown && (
//                                   <div className="submenu" style={{ animation: 'slideDown 0.2s ease-in-out' }}>
//                                     <ul className="menu-col-2">
//                                       {subitem.dropdown.map((dropdownItem, dropdownIndex) => (
//                                         <li key={dropdownIndex}>
//                                           <a href={dropdownItem.path}>{dropdownItem.item}</a>
//                                         </li>
//                                       ))}
//                                     </ul>
//                                   </div>
//                                 )}
//                               </li>
//                             );
//                           })}
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default FlyoutMenu;


















































//<-------------------------------------------------------------88----------------------------->main navbar

// import React, { useState , useEffect } from 'react';
// import { Link } from "react-router-dom";
// import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";
// import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";

// const subcategoryImages = {
//   Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
//   "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
//   Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
//   SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
//   Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
//   HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
//   Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
//   Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
//   "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
//   Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
//    Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
//    Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
//    Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
//    OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
//    haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
//   // Add more category images as needed
// };

// const FlyoutMenu = ({menus}) => {
//   const [isMobileView, setIsMobileView] = useState(false);
//   const [currentLink, setCurrentLink] = useState(null);
//   const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//      const [mobileMenuOpen, setMobileMenuOpen] = useState(false);


//   // Toggle mobile menu
//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//     setOpenSubmenuIndex(null); // Close any open submenu when toggling the mobile menu
//   };

//   // Toggle submenu based on its index
//   // const toggleSubMenu = (index) => {
//   //   setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
//   // };

//   const toggleSubMenu = (index) => {
//     if (isMobileView) {
//       // Close the currently open submenu when clicking on another submenu item
//       if (openSubmenuIndex === index) {
//         setOpenSubmenuIndex(null);
//       } else {
//         setOpenSubmenuIndex(index);
//       }
//     } else {
//       setOpenSubmenuIndex(index); // For desktop view, toggle the submenu on click
//     }
//   };

//   const handleDesktopSubMenuEvents = (index) => {
//     if (!isMobileView) {
//       // Only apply these events for desktop view
//       return {
//         onMouseEnter: () => toggleSubMenu(index),
//         onMouseLeave: () => toggleSubMenu(index),
//       };
//     }
//     return {}; // No events for mobile view
//   };

//   const handleSubmenuClick = (index) => {
//     if (isMobileView) {
//       // Toggle the submenu for mobile view
//       toggleSubMenu(index);
//     }
//   };



//       const mobileMenuClass = isMobileMenuOpen ? 'mobile-menu-open' : '';

   
    

//   return (

//     <nav className={`container ${isMobileView ? "mobile-view" : "desktop-view"}`} id="mega_menu">
//        {/* Add this overlay div */}
//        <div
//         className={`overlay ${isMobileView ? 'open' : ''}`}
//         onClick={toggleMobileMenu}
//       ></div>
//       <input type="checkbox" id="touch-menu" />
//       <label htmlFor="touch-menu" className={`mobile-menu ${isMobileView ? "mobile-visible" : "desktop-visible"}`} onClick={toggleMobileMenu}>
//         <strong><img src="https://cdn-icons-png.flaticon.com/128/7710/7710488.png"></img></strong>
//       </label>
//       <img
//         src="https://cdn.storehippo.com/s/62fe03157f1fa3a21747b8c4/63181d9166770bad3bd50979/webp/asset-1-1--480x480.png"
//         alt="Shop" className="D2U"
//       />
//       {/* <div className={`menu ${isMobileView ? "open" : ""}`}> */}
//       <div className={`menu ${isMobileView ? "open" : ""} menu-mobile-scroll`}>
//         <ul className="menu-main">
//           {menus?.map((item, index) => (
//             <li
//               key={index}
//               // className={`drop-down ${openSubmenuIndex === index ? "active" : ""}`}
//             className={`drop-down ${openSubmenuIndex === index ? "active" : ""}`}
//               {...handleDesktopSubMenuEvents(index)} // Apply events for desktop view
//             >
//                {/* Check if the current item is not "Home" or if it's not a mobile view */}
//     {!isMobileView || item.item !== "Home1" ? (
//       <>
//               <input
//                 type="checkbox"
//                 id={`touch-menu-${index}`}
//                 className="touch-menu"
//                 checked={openSubmenuIndex === index}
//                 // onChange={() => {}}
//                 onChange={() => toggleSubMenu(index)}
//               />
//               <label
//                 htmlFor={`touch-menu-${index}`}
//                 className="mobile-submenu"
//                 onClick={() => toggleSubMenu(index)} // Added onClick handler
//                 data-home={item.item === "Home1" ? "true" : "false"}
//                 // onTouchStart={(e) => {
//                 //   e.preventDefault(); // Prevent the double-click issue
//                 //   toggleSubMenu(index);
//                 // }}
//                 // onTouchEnd={(e) => e.preventDefault()}
//               >
//               </label>
//               </>
//     ) : null}

//               <Link to={item.path}>
//                 <h3 className="drop">
//                   <li className="drop-down">
//                     <span style={{ fontWeight: "bold", marginTop: "18px" }}>{item.item}</span>
//                   </li>
//                 </h3>
//               </Link>

//               {item.submenu && openSubmenuIndex === index && (
//                 <div className="ruby-grid ruby-grid-lined">
//                   <div className="menu-sub" style={{ animation: "slideDown 0.2s ease-in-out" }}>
//                     <div className="submenu menu-column-4 menu-mobile-scroll">
//                       <div className="menu-col-1">
//                         <ul className="submenu-items">
//                           {item?.submenu?.map((subitem, subindex) => {
//                             const checkboxId = `touch-submenu-${index}-${subindex}`;
//                             const labelId = `label-submenu-${index}-${subindex}`;
//                             const SubcategoryImage = subcategoryImages[subitem.item];
     
//                             return (
//                               <li key={subindex}
//                               data-index={subindex} // Add a data-index attribute for touch event handling
//                               onClick={() => handleSubmenuClick(index)}
//                               >
//                                 <input
//                                   type="checkbox"
//                                   id={checkboxId}
//                                    className="touch-submenu"
//                                 />
//                                 <label
//                                   htmlFor={checkboxId}
//                                   className={`mobile-submenu ${isMobileView ? "mobile-visible" : "desktop-visible"}`}
//                                   id={labelId}
//                                 ></label>
//                                 <Link to={subitem.path}
//                                 >
//                                   {SubcategoryImage && (
//                                     <img
//                                       src={SubcategoryImage}
//                                       className="subcategory_image"
//                                       style={{
//                                         width: "10%",
//                                         height: "10%",
//                                         marginRight: "1%"
//                                       }}
//                                     />
//                                   )}
//                                   <span style={{ fontWeight: "bold" }}>
//                                     {subitem.item}
//                                   </span>
//                                 </Link>
//                                 {subitem.dropdown && (
//                                   <div className="submenu" style={{ animation: 'slideDown 0.2s ease-in-out' }}>
//                                      {/* <div className={`submenu ${openSubmenuIndex === index ? 'open' : ''}`} style={{ animation: 'slideDown 0.2s ease-in-out' }}> */}
//                                     <ul className="menu-col-2">
//                                       {subitem.dropdown.map(
//                                         (dropdownItem, dropdownIndex) => (
//                                           <li key={dropdownIndex}>
//                                             <a href="javascript:">
//                                               {dropdownItem.item}
//                                             </a>
//                                           </li>
//                                         )
//                                       )}
//                                     </ul>
//                                   </div>
//                                 )}
//                               </li>
//                             );
//                           })}
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default FlyoutMenu;

//<------------------------------------------------------------------

// import React, { useState , useEffect } from 'react';
// import { Link } from "react-router-dom";
// import { FaMobileAlt, FaTv, FaTshirt, FaPlus, FaMinus } from "react-icons/fa";
// import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";

// const subcategoryImages = {
//   Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
//   "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
//   Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
//   SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
//   Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
//   HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
//   Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
//   Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
//   "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
//   Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
//    Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
//    Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
//    Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
//    OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
//    haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
//   // Add more category images as needed
// };

// const FlyoutMenu = ({menus, isMobileView}) => {
//   // const [isMobileView, setIsMobileView] = useState(false);
//   const [currentLink, setCurrentLink] = useState(null);
//   const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//     const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

//   // const toggleSubMenu = (index) => {
//   //   setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
//   // };

//   // // Toggle mobile menu
//   // const toggleMobileMenu = () => {
//   //   if (!isMobileView) {
//   //   setIsMobileMenuOpen(!isMobileMenuOpen);
//   // }
//   // };
//   // Toggle submenu for mobile view with a single click
//   const toggleMobileSubMenu = (index) => {
//     if (isMobileView) {
//       setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
//     }
//   };

//   // Toggle submenu based on its index
//   const toggleSubMenu = (index) => {
//      if (!isMobileView) {
//     setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
//   }
// };

// // Toggle mobile menu (plus icon in the menu bar)
// const toggleMobileMenu = () => {
//   setMobileMenuOpen(!mobileMenuOpen);
//   setOpenSubmenuIndex(null); // Close any open submenu when toggling the mobile menu
// };

  
    
//       const mobileMenuClass = isMobileMenuOpen ? 'mobile-menu-open' : '';
    

//   return (

//     <nav className={`container ${isMobileView ? "mobile-view" : "desktop-view"}`} id="mega_menu">
//        {/* Add this overlay div */}
//        <div
//         className={`overlay ${isMobileView ? 'open' : ''}`}
//         onClick={toggleMobileMenu}
//       ></div>
//       <input type="checkbox" id="touch-menu" />
//       <label htmlFor="touch-menu" className={`mobile-menu ${isMobileView ? "mobile-visible" : "desktop-visible"}`} onClick={toggleMobileMenu}>
//         <strong>&#9776;</strong>
//       </label>
//       <img
//         src="https://cdn.storehippo.com/s/62fe03157f1fa3a21747b8c4/63181d9166770bad3bd50979/webp/asset-1-1--480x480.png"
//         alt="Shop" className="D2U"
//       />
//       {/* <div className={`menu ${isMobileView ? "open" : ""}`}> */}
//       <div className={`menu ${isMobileView ? "open" : ""} menu-mobile-scroll`}>
//         <ul className="menu-main">
//           {menus?.map((item, index) => (
//             <li
//               key={index}
//               className={`drop-down ${openSubmenuIndex === index ? "active" : ""}`}
//               onMouseEnter={() => toggleSubMenu(index)} // For desktop view
//             >
//                {/* Check if the current item is not "Home" or if it's not a mobile view */}
//     {!isMobileView || item.item !== "Home1" ? (
      
//       <>
//               <input
//                 type="checkbox"
//                 id={`touch-menu-${index}`}
//                 className="touch-menu"
//                 checked={openSubmenuIndex === index}
//                 onChange={() => {}}
//               />
//               <label
//                 htmlFor={`touch-menu-${index}`}
//                 className="mobile-submenu"
//                 onClick={() => toggleMobileSubMenu(index)} // For mobile view
//                 data-home={item.item === "Home1" ? "true" : "false"}
//               >
//               </label>
//               </>
//     ) : null}

//               <Link to={item.path}>
//                 <h3 className="drop">
//                   <li className="drop-down">
//                     <span style={{ fontWeight: "bold", marginTop: "18px" }}>{item.item}</span>
//                   </li>
//                 </h3>
//               </Link>

//               {item.submenu && openSubmenuIndex === index && (
//                 <div className="ruby-grid ruby-grid-lined">
//                   <div className="menu-sub" style={{ animation: "slideDown 0.2s ease-in-out" }}>
//                     <div className="submenu menu-column-4 menu-mobile-scroll">
//                       <div className="menu-col-1">
//                         <ul className="submenu-items">
//                           {item?.submenu?.map((subitem, subindex) => {
//                             const checkboxId = `touch-submenu-${index}-${subindex}`;
//                             const labelId = `label-submenu-${index}-${subindex}`;
//                             const SubcategoryImage = subcategoryImages[subitem.item];
//                             return (
//                               <li key={subindex}>
//                                 <input
//                                   type="checkbox"
//                                   id={checkboxId}
//                                   className="touch-submenu"
//                                   // checked={openSubmenuIndex === index} Remove this line
//                                 />
//                                 <label
//                                   htmlFor={checkboxId}
//                                   className={`mobile-submenu ${isMobileView ? "mobile-visible" : "desktop-visible"}`}
//                                   id={labelId}
//                                 ></label>
//                                 <Link to={subitem.path}>
//                                   {SubcategoryImage && (
//                                     <img
//                                       src={SubcategoryImage}
//                                       className="subcategory_image"
//                                       style={{
//                                         width: "10%",
//                                         height: "10%",
//                                         marginRight: "1%"
//                                       }}
//                                     />
//                                   )}
//                                   <span style={{ fontWeight: "bold" }}>
//                                     {subitem.item}
//                                   </span>
//                                 </Link>
//                                 {subitem.dropdown && (
//                                   <div className="submenu" style={{ animation: 'slideDown 0.2s ease-in-out' }}>
//                                     <ul className="menu-col-2">
//                                       {subitem.dropdown.map(
//                                         (dropdownItem, dropdownIndex) => (
//                                           <li key={dropdownIndex}>
//                                             <a href="javascript:">
//                                               {dropdownItem.item}
//                                             </a>
//                                           </li>
//                                         )
//                                       )}
//                                     </ul>
//                                   </div>
//                                 )}
//                               </li>
//                             );
//                           })}
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default FlyoutMenu;















//<--------------------00000000000000000000---------------------------------->
// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";
// import { HiX,HiChevronDown, HiChevronUp } from "react-icons/hi";





// // const subcategoryIcons = {
// //   Mobile: FaMobileAlt,
// //   TV: FaTv,
// //   Clothes: FaTshirt,
// //   // Add more category icons as needed
// // };

// const subcategoryImages = {
//   Mobile: "https://cdn.pixabay.com/photo/2016/10/05/17/11/smartphone-1717163_1280.png",
//   "T.V": "https://cdn-icons-png.flaticon.com/128/2956/2956558.png ",
//   Clothes: "https://cdn-icons-png.flaticon.com/128/1926/1926322.png",
//   SmartWatch: "https://cdn-icons-png.flaticon.com/128/617/617694.png",
//   Electronics: "https://cdn-icons-png.flaticon.com/128/3696/3696504.png",
//   HeadPhones: "https://cdn-icons-png.flaticon.com/128/1166/1166925.png",
//   Cooker: "https://cdn-icons-png.flaticon.com/128/8700/8700295.png",
//   Speakers: "https://cdn-icons-png.flaticon.com/128/2061/2061136.png",
//   "Home Appliances": "https://cdn-icons-png.flaticon.com/128/3362/3362679.png",
//   Samsung: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/samsung-512.png",
//    Apple: "https://cdn-icons-png.flaticon.com/128/270/270781.png",
//    Vivo: "https://cdn.iconscout.com/icon/free/png-512/free-vivo-282151.png?f=avif&w=256",
//    Xiaomi: "https://w7.pngwing.com/pngs/122/182/png-transparent-xiaomi-logo-xiaomi-mi-a1-smartphone-internet-business-smartphone-angle-electronics-text-thumbnail.png",
//    OnePlus: "https://cdn.iconscout.com/icon/free/png-512/free-oneplus-2849930-2370671.png?f=avif&w=256",
//    haeir: "https://w1.pngwing.com/pngs/487/294/png-transparent-home-logo-haier-refrigerator-air-conditioners-home-appliance-haier-hrf665isb2-haier-america-company-llc-haier-hrf664isb2-thumbnail.png"
//   // Add more category images as needed
// };

// const FlyoutMenu = ({ menus }) => {
//   const [currentLink, setCurrentLink] = useState(null);

//   const toggleSubMenu = (index) => {
//     setCurrentLink(currentLink === index ? null : index);
//   };

//   return (
//     <nav className="container" id="mega_menu">
//       <ul className="menu-main">
//         {menus.map((item, index) => (
//           <li
//             key={index}
//             className={`drop-down ${currentLink === index ? "active" : ""}`}
//           >
//             <Link to={item.path}>
//               <h3>
//               <li className="drop-down"><span  style={{ fontWeight: "bold" }}>{item.item}</span></li>
//               </h3>
//             </Link>

//             {item.submenu && (
//               <div className="ruby-grid ruby-grid-lined">
//                 <div className="menu-sub">
//                   <div className="menu-col-1">
//                     <ul>
//                       {item.submenu.map((subitem, subindex) => {
//                         // const SubcategoryIcon = subcategoryIcons[subitem.item];
//                         const SubcategoryImage = subcategoryImages[subitem.item];
//                         return (
//                           <li key={subindex}>
//                             <Link to={subitem.path}>
//                             {SubcategoryImage && (
//                                 <img
//                                   src={SubcategoryImage}
//                                   style={{
//                                     width: "10%",
//                                     height: "10%",
//                                     marginRight: "1%"
//                                   }}
//                                 />
//                               )}
//                               {/* {SubcategoryIcon && <SubcategoryIcon />} */}
//                               <span style={{ fontWeight: "bold" }}>
//                                 {subitem.item}
//                               </span>
//                             </Link>
//                             {subitem.dropdown && (
//                               <ul className="menu-col-2">
//                                 {subitem.dropdown.map(
//                                   (dropdownItem, dropdownIndex) => (
//                                     <li key={dropdownIndex}>
//                                       <a href="javascript:">
//                                         {dropdownItem.item}
//                                       </a>
//                                     </li>
//                                   )
//                                 )}
//                               </ul>
//                             )}
//                           </li>
//                         );
//                       })}
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// };

// export default FlyoutMenu;


//<------------------------->



















//<----------------------------ROUGH----------------------------------------------------->

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { HiChevronDown, HiChevronUp } from "react-icons/hi";
// import { BiCategory } from "react-icons/bi";
// import { FaMobileAlt, FaTv, FaTshirt } from "react-icons/fa";


// const subcategoryIcons = {
//   Mobile: FaMobileAlt,
//   TV: FaTv,
//   Clothes: FaTshirt,
//   // Add more category icons as needed
// };

// const FlyoutMenu = ({ menus }) => {
//   const [currentLink, setCurrentLink] = useState(null);

//   const toggleSubMenu = (index) => {
//     setCurrentLink(currentLink === index ? null : index);
//   };

//   return (
//     <nav className="container" id="mega_menu">
//       <ul className="menu-main">
//         {menus.map((item, index) => (
//           <li key={index} className={`drop-down ${currentLink === index ? 'active' : ''}`}>
//             <Link to={item.path}>
//               <h3><span  style={{ fontWeight: "bold" }}>{item.item}</span></h3>
//             </Link>
//             {item.submenu && (
//               <div className="ruby-grid ruby-grid-lined">
//               <div className="menu-sub">
//                 <div className="menu-col-1">
//                   <ul>
//                     {item.submenu.map((subitem, subindex) => (
//                       <li key={subindex}>
//                         <Link to={subitem.path}>
//                         {/* {subitem.icons && <BiCategory className="sub-category-icon" />} */}
//                           <h4 className="menu-category"><span style={{ fontWeight: "bold" }}><i className="fa-solid fa-list-dropdown">{subitem.item}</i></span>
//                           </h4>
//                         </Link>
//                         {subitem.dropdown && (
//                           <ul className="menu-col-2">
//                             {subitem.dropdown.map((dropdownItem, dropdownIndex) => (
//                               <li key={dropdownIndex}>
//                                 <a href="javascript:">{dropdownItem.item}</a>
//                               </li>
//                             ))}
//                           </ul>
//                         )}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//               </div>
//             )}
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// };

// export default FlyoutMenu;