import ShopProductSingle from "./ShopProductSingle";
import ShopProductSingleMobile from "./ShopProductSingleMobile";
import { Spinner, Row, Col,Button,Card } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
const Items = ({ currentItems, gridView }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Call the handler initially

    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      className={`${
        gridView
          ? "featured__products grid"
          : "featured__products-lineView grid"
      }`}
    >
       {isMobile ?
      <Row>
      {currentItems &&
        currentItems?.map((product) => (
          <ShopProductSingleMobile
            gridView={gridView}
            key={product._id}
            product={product}
          />
        ))}
        </Row>
      : 
        currentItems?.map((product) => (
          <ShopProductSingle
            gridView={gridView}
            key={product._id}
            product={product}
          />
        ))
       
        }
    </div>
  );
};

export default Items;
