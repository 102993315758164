import React from "react";
import {
  AppDownload,
  Brands,
  DiscountBanner,
  Footer,
  HeroSlide,
  OurFeatures,
  Iphone,
  NewsLetter,
  Products,
  SupportArea,
  CategorySlide,
  OneBrand
} from "../../components";
import { Helmet } from "react-helmet";
const Home = () => (
  <div style={{padding:"1%"}}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>D2U - Ecommerce</title>
    </Helmet>
    <CategorySlide />
    <HeroSlide />
    <Products />
    <DiscountBanner />
    <Brands />
    <OneBrand />
    <SupportArea />
    <OurFeatures />
    <AppDownload />
    <Footer />
  </div>
);

export default Home;
