import { combineReducers } from "redux";

import { cartReducer } from "./cartReducer";
import { categoryReducer } from "./categoryReducer";
import {
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
} from "./orderReducer";
import {
  newReviewReducer,
  productByIdReducer,
  productReducer,
  shopReducer,
  productCategoryReducer
} from "./productReducer";
import {
  userLoginReducer,
  userLogoutReducer,
  userRegisterReducer,
} from "./userReducer";
import { wishlistReducer } from "./wishlistReducer";
import {
  createBrandReducer,
  brandReducer,
} from "./brandReducer";

import stateReducer from "./stateReducer";

import bannerReducer from "./bannerReducer";

import promoReducer from "./promoReducer";
import buyReducer from './buyReducer';

import {agentCheckReducer} from "./agentReducer";
import { createOrderReducer, capturePaymentReducer } from './razorpayReducer';

import {otpRegisterReducer} from './otpReducer'

export default combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userLogout: userLogoutReducer,
  allProducts: productReducer,
  productById: productByIdReducer,
  cart: cartReducer,
  shopProduct: shopReducer,
  allCategories: categoryReducer,
  newOrder: newOrderReducer,
  myOrders: myOrdersReducer,
  orderDetails: orderDetailsReducer,
  wishlist: wishlistReducer,
  newReview: newReviewReducer,
  allBrands: brandReducer,
  stateData:stateReducer,
  bannerdata:bannerReducer,
  buyNow: buyReducer,
  promodata:promoReducer,
  checkAgentReducer:agentCheckReducer,
  createOrder: createOrderReducer,
  capturePayment: capturePaymentReducer,
  otpReducer:otpRegisterReducer,
  categoryProducts:productCategoryReducer,
});
