import axios from "axios";
import { BASE_URL } from "../../config";
import {

  ALL_BRANDS_LOADING,
  ALL_BRANDS_SUCCESS,
  ALL_BRANDS_FAIL,

} from "../constants/brandConstants";


// Get All Brands
export const getAllBrands = (token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    dispatch({ type: ALL_BRANDS_LOADING });

    const { data } = await axios.get(`${BASE_URL}/api/brand`,config);

    dispatch({
      type: ALL_BRANDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_BRANDS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
