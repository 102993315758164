import SingleProduct from "./SingleProduct/SingleProduct";
import { useDispatch, useSelector } from "react-redux";
import { useEffect,useState } from "react";
import { getAllProduct } from "../../../redux/actions/productActions";
import { Spinner, Row, Col } from "react-bootstrap"; 
import "./SingleProduct/single.scss"
import { Link } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import ShopProductSingle from "../../../pages/Shop/ShopProductSingle";
import ShopProductSingleMobile from "../../../pages/Shop/ShopProductSingleMobile";
import ShopProduct from "../../../pages/Shop/ShopProduct";
import  SingleProductMobile  from "../../../pages/Shop/SingleProductMobile";

const Products = ({product,gridView}) => {
  const dispatch = useDispatch();
  const productsData = useSelector((state) => state.allProducts);
  const { products, loading, error } = productsData;
  const token = useSelector((state) => state.userLogin?.userInfo?.access_token);
  const sx = useSelector((state) => state.userLogin?.userInfo?.user);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    console.log("products jsx common page",token,sx.sn)
    dispatch(getAllProduct(token,sx.sn));
  }, [dispatch]);


  useEffect(() => {

  // Add event listener to handle screen width changes
  window.addEventListener("resize", handleResize);
  // Initial check for screen width on component mount
  handleResize();

  // Clean up event listener on component unmount
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

// Function to handle screen width changes
const handleResize = () => {
  setIsMobile(window.innerWidth <= 768);
};


  return (
    <>
      <div classname="LR2" style={{marginTop: "6%",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between"}}>
        <h3 className="featured__title">Latest Products </h3>

          {/* <button className="view-all-button">View All</button> */}
          <Link className="view-all-button" to={`/shop/`} >View All </Link>
        </div>

        <div className="featured__products" style={{display:"grid"}}>
          {loading ? (
            <Spinner
              style={{ marginLeft: "50%", marginTop: "5%" }}
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : error ? (
            <h2
              style={{
                color: "#333",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {error}
            </h2>
          ) : (
            <>
            { isMobile ?
            <Row>
              {products &&
                products.slice(0, 8).map((product) => (
                  <Link to={`/product/${product?._id}`}>
                  <Col xs={5} sm={6} md={4} lg={3} key={product?._id} style={{marginLeft:'3.5%'}}>
                    <SingleProductMobile product={product} />
                  </Col>
                  </Link>
                ))}
                </Row>
                :
            <>
            {products &&
              products.slice(0, 8).map((product) => (
                <Link to={`/product/${product?._id}`}>  <SingleProduct product={product} /> </Link>
              ))}
          </>
}
           </>
            )}
            </div>
       </>
  );
};


export default Products;
