import axios from 'axios';
import { BASE_URL } from "../../config";
import {
  FETCH_STATE_REQUEST,
  FETCH_STATE_SUCCESS,
  FETCH_STATE_FAILURE
} from '../constants/stateConstants';

export const fetchState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STATE_REQUEST });

    axios.get( `${BASE_URL}/api/state`)
      .then(response => {
        dispatch({
          type: FETCH_STATE_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_STATE_FAILURE,
          payload: error.response.data.error
        });
      });
  };
};
