import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineHeart, AiOutlineEye, AiFillHeart } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { addItemsToCart } from "../../redux/actions/cartActions";
import { ProductRating } from "../../components";
import { addItemsToWishlist } from "../../redux/actions/wishlistActions";
import ProductModal from "../../components/common/ProductModal/ProductModal";
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { FiHeart, FiStar } from 'react-icons/fi';
import { Spinner, Row, Col,Button,Card } from "react-bootstrap";
import './shop.scss'

const ShopProductSingleMobile = ({ product, gridView }) => {
  const { cartItems } = useSelector((state) => state.cart);
  const [showmodal, setShowModal] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const { addToast } = useToasts();

  const addOrNot = cartItems?.find((item) => item.product === product._id);
  const dispatch = useDispatch();
  const addToCartHandler = () => {
    dispatch(addItemsToCart(product?._id, 1, addToast));
  };


  const { wishlistItems } = useSelector((state) => state.wishlist);

  const addWishlistOrNot = wishlistItems?.find(
    (item) => item.product === product._id
  );

  const addToWishlistHandler = () => {
    dispatch(addItemsToWishlist(product?._id, 1, addToast));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set the breakpoint width for mobile devices
    };

    handleResize(); // Check the initial viewport width

    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);
  const cond = product?.images?.length > 0;
  let content = null;
  let contentList = null;
  if (cond) {
    content = <img className="default-img" src={product?.images[0].public_id} alt="" />;
    contentList = <img className="default-img" src={product?.images[0].thumbnail} alt="" />;
  } else {
    content = <img className="default-img" src={product?.images.public_id} alt="" />
    contentList = <img className="default-img" src={product?.images.thumbnail} alt="" />;
  }
  //console.log(cond,"Prod",product);
  return (
    <>

      {gridView ? (
  
        
     
              <Col xs={6} sm={6} md={4} lg={3} key={product?._id}>
                 
      <Card className="mb-4">
        <div className="CI"><Card.Img variant="top" style={{width:"50%"}} src={product?.images[0]?.thumbnail} alt="" /></div>
        <Card.Body>
          <Card.Title>
            <Link to={`/product/${product._id}`}>{product.shortname}</Link>
          </Card.Title>
          <Card.Text>
          <div className="off" style={{ color:"green"}}>10% Off</div>
            <div className="product-price">
           
  <div><s>&#8377;{(product.price) / 10}</s></div>
              <span>&#8377;{product.price}</span>
            </div>
          </Card.Text>
          <div className="product-action">
            {/* <div className="pro-same-action pro-wishlist">
              <Button
                disabled={addWishlistOrNot?.quantity > 0}
                onClick={addToWishlistHandler}
                title={
                  addWishlistOrNot?.quantity > 0
                    ? "Added to Wishlist"
                    : "Add to Wishlist"
                }
              >
                {addWishlistOrNot?.quantity > 0 ? (
                  <AiFillHeart style={{ color: "red" }} />
                ) : (
                  <AiOutlineHeart />
                )}
              </Button>
            </div> */}
            {/* <div className="pro-same-action pro-cart" style={{textAlign: "center"}}>
              {product?.Stock && product?.Stock > 0 ? (
                <Button
                  disabled={addOrNot?.quantity > 0}
                  onClick={addToCartHandler}
                  className="cart-btn"
                  title={
                    addOrNot?.quantity > 0 ? "Added to Cart" : "Add to Cart"
                  }
                >
              
                  {addOrNot?.quantity > 0 ? "Added" : "Add To Cart"}
                </Button>
              ) : (
                <Button disabled className="active">
                  Out of Stock
                </Button>
              )}
            </div> */}
   
          </div>
        </Card.Body>
      </Card>
      {/* quick view modal */}
      <ProductModal
        product={product}
        showmodal={showmodal}
        setShowModal={setShowModal}
      />

              </Col>
            


      ) : (
        // <div className={`product-wrap product-grid-line-view`}>
        //   <div className="product-img-grid">
        //     <Link to={`/product/${product._id}`}>
        //       <img className="default-img" src={product?.images[0]?.thumbnail} alt="" />
        //     </Link>
        //   </div>
        //   <div className="product-content">
        //     <h3>
        //       <Link to={"/product/" + product._id}>{product.name}</Link>
        //     </h3>

        //     <div className="product-price">
        //       <span>${product.price}</span>
        //     </div>

        //     {/* <div className="product-rating">
        //       <ProductRating ratingValue={product.ratings} />({product.ratings})
        //     </div> */}

        //     {/* <div className="product-description">
        //       <p>{product.description.slice(0, 100) + "...."}</p>
        //     </div> */}

        //     <div className="product-description"
        //           dangerouslySetInnerHTML={{ __html: product.description.slice(0, 100) + "...." }}
        //         />


        //     <div className="buttons-group">
        //       <div className="buy-now">
        //         {product?.Stock && product?.Stock > 0 ? (
        //           <button
        //             disabled={addOrNot?.quantity > 0}
        //             onClick={addToCartHandler}
        //             className="cart-btn"
        //           >
        //             <FiShoppingCart />
        //             {addOrNot?.quantity > 0 ? "Added" : "Buy Now"}
        //           </button>
        //         ) : (
        //           <button disabled className="active">
        //             Out of Stock
        //           </button>
        //         )}
        //       </div>

        //       <div className="buy-now pro-wishlist">
        //         <button
        //           disabled={addWishlistOrNot?.quantity > 0}
        //           onClick={addToWishlistHandler}
        //           title={
        //             addWishlistOrNot?.quantity > 0
        //               ? "Added to Wishlist"
        //               : "Add to Wishlist"
        //           }
        //         >
        //           {addWishlistOrNot?.quantity > 0 ? (
        //             <AiFillHeart style={{ color: "red" }} />
        //           ) : (
        //             <AiOutlineHeart />
        //           )}
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div className="prod-master">
          <div className="productS">
            <div className="product-thumbnail">
              <Link to={`/product/${product._id}`}>
                {contentList}
              </Link>
            </div>
            <div className="product-details">
              <div className="product-price">
                <div>{product.brand}</div>
              </div>
              <h3>
                <Link to={"/product/" + product._id}>{product.name}</Link>
              </h3>
              <div className="product-price">
                <div className="off">10% Off</div>
                <div><s>&#8377;{(product.price) / 10}</s></div>
                <div>&#8377;{product.price}</div>
              </div>


              <div className="product-attributes">
                {product.attributes?.map((a) => (
                  <span className="attribute"> {a.value}</span>
                ))}
              </div>
              <div className="buttons-groupS">
                <div className="buy-nowS">
                  {product?.Stock && product?.Stock > 0 ? (
                    <button
                      disabled={addOrNot?.quantity > 0}
                      onClick={addToCartHandler}
                      className="cart-btnS"
                    >
                      <FiShoppingCart />
                      {addOrNot?.quantity > 0 ? "Added" : "Add To Cart"}
                    </button>
                  ) : (
                    <button disabled className="active">
                      Out of Stock
                    </button>
                  )}
                </div>

              </div>
            </div>
          </div>


        </div>



      )}

      {/* quick view modal */}
      <ProductModal
        product={product}
        showmodal={showmodal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default ShopProductSingleMobile;
