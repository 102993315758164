// razorpayReducers.js

import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAILURE,
    CAPTURE_PAYMENT_REQUEST,
    CAPTURE_PAYMENT_SUCCESS,
    CAPTURE_PAYMENT_FAILURE,
  } from '../constants/razorpayConstants';
  
  const initialState = {
    loading: false,
    order: null,
    error: null,
  };
  
  export const createOrderReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_ORDER_REQUEST:
        return { ...state, loading: true };
      case CREATE_ORDER_SUCCESS:
        return { ...state, loading: false, order: action.payload, error: null };
      case CREATE_ORDER_FAILURE:
        return { ...state, loading: false, order: null, error: action.payload };
      default:
        return state;
    }
  };
  
  const capturePaymentInitialState = {
    loading: false,
    paymentResult: null,
    error: null,
  };
  
  export const capturePaymentReducer = (state = capturePaymentInitialState, action) => {
    switch (action.type) {
      case CAPTURE_PAYMENT_REQUEST:
        return { ...state, loading: true };
      case CAPTURE_PAYMENT_SUCCESS:
        return { ...state, loading: false, paymentResult: action.payload, error: null };
      case CAPTURE_PAYMENT_FAILURE:
        return { ...state, loading: false, paymentResult: null, error: action.payload };
      default:
        return state;
    }
  };
  