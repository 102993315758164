import {
    FETCH_BANNERS_REQUEST,
    FETCH_BANNERS_SUCCESS,
    FETCH_BANNERS_FAILURE,
    CREATE_BANNER_REQUEST,
    CREATE_BANNER_SUCCESS,
    CREATE_BANNER_FAILURE,
    UPDATE_BANNER_REQUEST,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER_FAILURE,
    DELETE_BANNER_REQUEST,
    DELETE_BANNER_SUCCESS,
    DELETE_BANNER_FAILURE,
  } from '../constants/bannerConstants';
  
  const initialState = {
    banners: [],
    loading: false,
    error: null,
  };
  
  const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BANNERS_REQUEST:
      case CREATE_BANNER_REQUEST:
      case UPDATE_BANNER_REQUEST:
      case DELETE_BANNER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_BANNERS_SUCCESS:
        return {
          ...state,
          banners: action.payload,
          loading: false,
          error: null,
        };
      case CREATE_BANNER_SUCCESS:
        return {
          ...state,
          banners: [...state.banners, action.payload],
          loading: false,
          error: null,
        };
      case UPDATE_BANNER_SUCCESS:
        const updatedBanners = state.banners.map((banner) =>
          banner._id === action.payload._id ? action.payload : banner
        );
        return {
          ...state,
          banners: updatedBanners,
          loading: false,
          error: null,
        };
      case DELETE_BANNER_SUCCESS:
        const filteredBanners = state.banners.filter(
          (banner) => banner._id !== action.payload
        );
        return {
          ...state,
          banners: filteredBanners,
          loading: false,
          error: null,
        };
      case FETCH_BANNERS_FAILURE:
      case CREATE_BANNER_FAILURE:
      case UPDATE_BANNER_FAILURE:
      case DELETE_BANNER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default bannerReducer;
  