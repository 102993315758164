import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { clearErrors, myOrders } from "../../../../redux/actions/orderActions";
import Loading from "../../../common/Loading/Loading";
import { Helmet } from "react-helmet";

const MyOrders = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { loading, error, orders } = useSelector((state) => state.myOrders);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
      addToast(error, { appearance: "error", autoDismiss: true });
    }
    dispatch(myOrders());
  }, [dispatch, error, addToast]);

  useEffect(() => {
    setFilteredOrders(
      orders.filter((order) =>
        order._id.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [orders, searchTerm]);

  const handleSort = (field) => {
    if (field === sortBy) {
      setFilteredOrders([...filteredOrders].reverse());
      setSortBy("");
    } else {
      const sortedOrders = [...filteredOrders].sort((a, b) =>
        a[field].localeCompare(b[field])
      );
      setFilteredOrders(sortedOrders);
      setSortBy(field);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <section className="myorders container-div">
      {/* ... */}
      <input
        type="text"
        placeholder="Search by Order ID"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div>
        <Table responsive="sm">
          <thead>
            <tr className="myorders__header">
              <th onClick={() => handleSort("_id")}>Product</th>
              <th>Name</th>
              <th onClick={() => handleSort("totalPrice")}>Price</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  <Loading backdrop />
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  <h3 style={{ color: "#333", fontWeight: "500" }}>{error}</h3>
                </td>
              </tr>
            ) : (
              <>
                {filteredOrders.length > 0 ? (
                  filteredOrders.map(({ _id, orderStatus, totalPrice, orderItems, shippingInfo, createdAt }) => (
                    <tr key={_id}>
                      <td>
                        {orderItems?.map((order, i) => (
                          <p key={i}>{order.name}</p>
                        ))}
                      </td>
                      <td>{shippingInfo.name}</td>
                      <td>&#8377;{totalPrice.toFixed(2)}</td>
                      <td>{shippingInfo?.phoneNo}</td>
                      <td>{shippingInfo?.address}</td>
                      <td>{new Date(createdAt).toDateString()} - {new Date(createdAt).toLocaleTimeString()}</td> {/* Render date in a good format */}
                      <td>
                        <button>{orderStatus}</button>
                      </td>
                      <td title="Order Details">
                        <Link className="order-detail" to={`/dashboard/order/${_id}`}>
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: "center" }}>
                      No orders found.
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
      {/* Pagination */}
      <div className="pagination">
        <ul className="pagination-list">
          {filteredOrders.length > 0 &&
            Array.from({ length: Math.ceil(filteredOrders.length / itemsPerPage) }).map((_, index) => (
              <li key={index + 1} className={index + 1 === currentPage ? "active" : ""}>
                <button onClick={() => paginate(index + 1)}>{index + 1}</button>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};

export default MyOrders;
