import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { clearErrors, createOrder } from "../../redux/actions/orderActions";
import {createRazorPayOrder,capturePayment} from "../../redux/actions/razorpayActions";
import { Helmet } from "react-helmet";

import { Spinner } from "react-bootstrap";
import { BASE_URL } from "../../config";
import { BUY_NOW, RESET,SUCCESS } from "../../redux/constants/buyConstants"; // Import the BUY_NOW and RESET constants

const CheckoutForm = () => {
  const orderInfo = JSON.parse(sessionStorage.getItem("buyInfo"));
 // const buyInfo = JSON.parse(sessionStorage.getItem("buyInfo"));
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMesssage] = useState("");
  const { order:orderR, loading:loadingR, error:errorR } = useSelector((state) => state.createOrder);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const payBtn = useRef(null);

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const a = useSelector((state) => state.buyNow);
  console.log("buy reducer ",a)
  const { purchasedProduct, success } = useSelector((state) => state.buyNow); // Destructure purchasedProduct and success from buyreducer state

  const auth = useSelector((state) => state.userLogin?.userInfo);
  const { user } = auth;
  const token = useSelector((state) => state.userLogin?.userInfo?.access_token);


  const { error } = useSelector((state) => state.newOrder);

  const paymentData = {
    amount: Math.round(orderInfo?.totalPrice * 100),
  };
  console.log(purchasedProduct,"payment page");
  const order = {
    shippingInfo,
    orderItems: purchasedProduct,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    shippingPrice: orderInfo.shippingCharges,
    totalPrice: orderInfo.totalPrice,
  };
  const paymentHandler = async (e) => {
    const API_URL = 'http://localhost:5000/api/razorpay/'
    e.preventDefault();
    // const orderUrl = `${API_URL}order`;
    console.log(purchasedProduct.product,"Pay hanlde",order,purchasedProduct.price)
    const response = dispatch(createRazorPayOrder(purchasedProduct.price,purchasedProduct.product,token));
    
    const { data } = response;
    if (loadingR) {
    console.log("Loading order...",data.id);
    }
  
    if (errorR) {
      console.log("Error order...",errorR.message);
    }
  
    if (orderR) {
      // If the order is successfully created, you can access order details here
      console.log('Order doner:', orderR);
      dispatch({ type: RESET });
    }

    console.log(response,"Razor 1 ",data)
    const options = {
      key: process.env.RAZOR_PAY_KEY_ID,
      name: "Your App Name",
      description: "Some Description",
      order_id: orderR.id,
      handler: async (response) => {
        try {
         const paymentId = response.razorpay_payment_id;
         const url = `${API_URL}capture/${paymentId}`;
         const captureResponse = await axios.post(url, {})

         dispatch(capturePayment(paymentId));
         console.log(captureResponse.data);
        } catch (err) {
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    };
  const submitHandler = async (e) => {
    e.preventDefault();

    payBtn.current.disabled = true;

    try {
      setIsLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      // const { data } = await axios.post(
      //   `${BASE_URL}/api/payment/process`,
      //   paymentData,
      //   config
      // );

      // const client_secret = data.client_secret;

     // if (!stripe || !elements) return;

      // const result = await stripe.confirmCardPayment(client_secret, {
      //   payment_method: {
      //     card: elements.getElement(CardNumberElement),
      //     billing_details: {
      //       name: user.name,
      //       email: user.email,
      //       address: {
      //         line1: shippingInfo.address,
      //         city: shippingInfo.city,
      //         state: shippingInfo.state,
      //         postal_code: shippingInfo.pinCode,
      //         country: shippingInfo.country,
      //       },
      //     },
      //   },
      // });
    //  const result.paymentIntent.status === "succeeded"
      if (1===2) {
        payBtn.current.disabled = false;
      //  setErrorMessage(result?.error);
        setSuccessMesssage("");
        setIsLoading(false);
      } else {

        if ("succeeded" === "succeeded") {
          setSuccessMesssage("Payment succssfully done.");
          setErrorMessage("");
          setIsLoading(false);
          order.paymentInfo = {
            id: 123123,
            status: "Done",
          };
          let iOrders =[order];
          dispatch(createOrder(iOrders, token, navigate));

          // setErrorMessage

          // await axios.post(
          //   `${BASE_URL}/api/user/success`,
          //   {
          //     email: user.email,
          //   },
          //   config
          // );
        } else {
          setIsLoading(false);
          setSuccessMesssage("");
          setErrorMessage("There's some issue while processing payment ");
        }
      }
    } catch (error) {
      payBtn.current.disabled = false;
      setSuccessMesssage("");
      setErrorMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  useEffect(() => {
    if (error) {
      addToast(error, { appearance: "error", autoDismiss: true });
      dispatch(clearErrors());
    }
  }, [dispatch, error, addToast]);

  useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, { appearance: "error", autoDismiss: true });
    } else if (successMessage) {
      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      // navigate(redirect);
    }
  }, [addToast, errorMessage, successMessage]);
  // Check for success state and reset it after handling it
  // useEffect(() => {
  //   if (success) {
  //     // Handle the success action here, e.g., show a success message
  //     console.log('Payment successful');
  //     // Reset the success state to false
  //     dispatch({ type: RESET });
  //   }
  // }, [dispatch, success]);
  return (
    <div className="container-div payment-checkout">
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Payment</title>
        </Helmet>

        <div className="payment">
          <form className="payment__form" onSubmit={(e) => submitHandler(e)}>
            <h2>Card Info</h2>
         

            <button ref={payBtn} type="submit" className="button">
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                `Pay - ${orderInfo && orderInfo.totalPrice.toFixed(2)}`
              )}
            </button>
            {/* <input
              type="submit"
              value={`Pay - $${orderInfo && orderInfo.totalPrice}`}
              ref={payBtn}
              className="button"
            /> */}
          </form>
          <button onClick={paymentHandler}>Pay Now</button>
        </div>
      </>
    </div>
  );
};

const Payment = () => (
  <>
    <CheckoutForm />
  </>
);

export default Payment;
