export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAIL = "CREATE_BRAND_FAIL";
export const CREATE_BRAND_RESET = "CREATE_BRAND_RESET";

export const BRAND_DELETE_REQUEST = "BRAND_DELETE_REQUEST";
export const BRAND_DELETE_SUCCESS = "BRAND_DELETE_SUCCESS";
export const BRAND_DELETE_FAIL = "BRAND_DELETE_FAIL";
export const BRAND_DELETE_RESET = "BRAND_DELETE_RESET";

export const BRAND_BY_ID_REQUEST = "BRAND_BY_ID_REQUEST";
export const BRAND_BY_ID_SUCCESS = "BRAND_BY_ID_SUCCESS";
export const BRAND_BY_ID_FAIL = "BRAND_BY_ID_FAIL";
export const BRAND_BY_ID_RESET = "BRAND_BY_ID_RESET";

export const BRAND_UPDATE_REQUEST = "BRAND_UPDATE_REQUEST";
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS";
export const BRAND_UPDATE_FAIL = "BRAND_UPDATE_FAIL";
export const BRAND_UPDATE_RESET = "BRAND_UPDATE_RESET";

export const ALL_BRANDS_LOADING = "ALL_BRANDS_LOADING";
export const ALL_BRANDS_SUCCESS = "ALL_BRANDS_SUCCESS";
export const ALL_BRANDS_FAIL = "ALL_BRANDS_FAIL";

export const ALL_REVIEW_REQUEST = "ALL_REVIEW_REQUEST";
export const ALL_REVIEW_SUCCESS = "ALL_REVIEW_SUCCESS";
export const ALL_REVIEW_FAIL = "ALL_REVIEW_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";
