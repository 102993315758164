import axios from 'axios';
import { BASE_URL } from "../../config";
import {
  FETCH_BANNERS_REQUEST,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNERS_FAILURE,
} from '../constants/bannerConstants';

// Action: Fetch banners
export const fetchBanners = () => async (dispatch) => {
  
  try {
    dispatch({ type: FETCH_BANNERS_REQUEST });

    const response = await axios.get(`${BASE_URL}/api/banner`); // Adjust the API endpoint according to your backend
    dispatch({
      type: FETCH_BANNERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_BANNERS_FAILURE,
      payload: error.message,
    });
  }
};

