import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adharUpload, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useToasts } from "react-toast-notifications";
import { validRegister } from "../../utils/validation";
import { register } from "../../redux/actions/userActions";
import {fetchState} from "../../redux/actions/stateActions";
import { USER_REGISTER_RESET } from "../../redux/constants/userConstants";
import {ALL_AGENTS_SUCCESS} from "../../redux/constants/agentConstants"
import {OTP_REGISTER_SUCCESS} from "../../redux/constants/otpConstants"
import { Helmet } from "react-helmet";
import man from "./../../assets/images/man.png";
import { Modal, Button } from "react-bootstrap";
import {otpRegisterRequest, otpRegisterSuccess} from "../../redux/actions/otpActions"

import {checkAgent} from "../../redux/actions/agentActions";

const Register = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [newUser, setNewUser] = useState({
    adhaar: "",
    state: "",
    store: "",
    account: "",
    shop: "",
    pincode: "",
    IFSC: "",
    Bank: "",
    PAN: "",
    mobile: "",
    name: "",
    agentCode: "",
    otp: "",
  });

  const {
    adhaar,
    state,
    store,
    account,
    shop,
    pincode,
    IFSC,
    Bank,
    PAN,
    mobile,
    name,
    agentCode,
    otp,
  } = newUser;

  const stateData = useSelector((state) => state.stateData);
  const agentData = useSelector((state) => state.checkAgentReducer);
  const { states } = stateData;
  const { agents } = agentData;

  const [selectedState, setSelectedState] = useState(null);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const otpData = useSelector((state) => state.otpReducer);
  const { otpMatch } = otpData;

  const [typePass, setTypePass] = useState(false);
  const [typeCfPass, setTypeCfPass] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [resendTimer, setResendTimer] = useState(60);

  const userReg = useSelector((state) => state.userRegister);
  const { loading, error, userInfo: userRegInfo } = userReg;

  const [adharUpload, setAdharUpload] = useState();
  const [PANUpload, setPANUpload] = useState();

  const [disabler, setDisabler] = useState(false);
  const [agCode, setAgCode] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchState());
      } catch (error) {
        // Handle errors
        console.error("Error fetching state:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const handleAgentCheck = async () => {
      try {
        await dispatch(checkAgent(agentCode));
      } catch (error) {
        // Handle errors
        console.error("Error checking agent:", error);
      }
    };

    if (agentCode) {
      handleAgentCheck();
    }
  }, [dispatch, agentCode]);

  const handleChangeInput = (e) => {
    if (e.target.name === 'adharUpload' || e.target.name === 'PANUpload') {
      const file = e.target.files[0];
      setNewUser((prevUser) => ({
        ...prevUser,
        [e.target.name]: file,
      }));
      setSelectedState(e.target.value);
    } else {
      setSelectedState(e.target.value);
      setNewUser((prevUser) => ({
        ...prevUser,
        [e.target.name]: e.target.value,
      }));
    }
  };
  const handleAgentInput = async (e) => {
    const agentCodeValue = e.target.value;
    setNewUser((prevUser) => ({
      ...prevUser,
      [e.target.name]: agentCodeValue,
    }));

    try {
      await dispatch(checkAgent(agentCodeValue));
    } catch (error) {
      // Handle errors
      console.error("Error checking agent:", error);
    }
  };
  const isFormValid =
    adhaar !== "" &&
    state !== "" &&
    store !== "" &&
    account !== "" &&
    shop !== "" &&
    pincode !== "" &&
    IFSC !== "" &&
    Bank !== "" &&
    PAN !== "" &&
    mobile !== "" &&
    name !== "" &&
    agentCode !== "";

  const handleSendOTP = async () => {
    try {
      await dispatch(otpRegisterRequest(mobile, otp, "info"));
      setShowOTPModal(true);
      startResendTimer();
    } catch (error) {
      // Handle errors
      console.error("Error sending OTP:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const key in newUser) {
      if (newUser.hasOwnProperty(key)) {
        formData.append(key, newUser[key].toString());
      }
    }
    formData.append("PANUpload", PANUpload);
    formData.append("adharUpload", adharUpload);

    try {
      await dispatch(register(formData));
    } catch (error) {
      // Handle errors
      console.error("Error registering user:", error);
    }
  };

  useEffect(() => {
    const handleRegisterResult = () => {
      if (error) {
        dispatch({ type: USER_REGISTER_RESET });
        addToast(error, { appearance: "error", autoDismiss: true });
      } else if (userRegInfo) {
        dispatch({ type: USER_REGISTER_RESET });
        dispatch({ type: OTP_REGISTER_SUCCESS });
        addToast(userRegInfo?.message, {
          appearance: "success",
          autoDismiss: true,
        });
      }

      if (agents?.message === "Agent Exist") {
        setDisabler(true);
        dispatch({ type: ALL_AGENTS_SUCCESS });
        addToast(agents?.message, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    };

    handleRegisterResult();
  }, [dispatch, agentCode, agents?.message, userRegInfo, error]);

  const handleOtpInputChange = (e) => {
    const otp = e.target.value.replace(/\D/, "");
    setOtpInput(otp.slice(0, 4));
  };

  const startResendTimer = () => {
    setResendTimer(60);
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const handleResendOTP = () => {
    if (resendTimer === 0) {
      startResendTimer();
    }
  };

  const handleVerifyOTP = () => {
    dispatch(otpRegisterSuccess(mobile, otpInput));
    if (otpMatch === true) {
      setShowOTPModal(false);
      setDisabler(true);
    } else {
      addToast("Invalid OTP. Please try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setNewUser((prevUser) => ({
      ...prevUser,
      otp: otpInput,
    }));
  };

  const renderSendOTPButton = () => {
    return otpMatch && (
      <Button
        style={{ background: "#0dcaf0" }}
        className="login__form__submit"
        type="button"
        disabled={!isFormValid}
        onClick={handleSendOTP}
      >
        {loading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Sending OTP...
          </>
        ) : (
          "Send OTP"
        )}
      </Button>
    );
  };

  const renderOTPModal = () => {
    return (
      <Modal show={showOTPModal} onHide={() => setShowOTPModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="login__form__input"
            type="number"
            name="otp"
            value={otpInput}
            onChange={handleOtpInputChange}
            placeholder="Enter 4 Digit OTP"
            maxLength={4}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOTPModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleVerifyOTP}>
            Verify OTP
          </Button>
          <Button
            variant="link"
            onClick={handleResendOTP}
            disabled={resendTimer > 0}
          >
            Resend OTP {resendTimer > 0 && `(${resendTimer}s)`}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <section className="login-section1" style={{size:'20px'}}>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Register</title>
      </Helmet>
      <div className="login container-div">
        <h3 className="login__title" style={{marginTop:'24%'}}>Register</h3>
         {/* <img src={man} alt="Register" style={{width:'200px',paddingLeft:'120px'}}/> */}
        <form className="login__form" onSubmit={handleSubmit} style={{fontSize:'2%'}}>
        <img src={man} alt="Register" style={{width:'203px',paddingLeft:'145px',position:'relative'}}/>
        <div style={{marginTop:'17px'}}><br></br><br></br>
            <input
              className="login__form__input"
              type="text"
              name="agentCode"
              value={agentCode}
              id="agentCode"
              onChange={handleAgentInput}
              placeholder="Agent Code" 
               disabled={disabler}
             // { agents?.message === "Agent Exist" ? 'disabled' : ''}
            />
          </div>
        <div>
            <input
              className="login__form__input"
              type="text"
              name="name"
              value={name}
              id="name"
              onChange={handleChangeInput}
              placeholder="Owner Name" 
            />
          </div>
          <div>
            <input
              className="login__form__input"
              type="number"
              name="mobile"
              value={mobile}
              id="mobile"
              onChange={handleChangeInput}
              placeholder="Mobile Number(10 digits)" 
            />
          </div>
          <div>
            <input
              className="login__form__input"
              type="number"
              name="adhaar"
              value={adhaar}
              id="adhaar"
              onChange={handleChangeInput}
              placeholder="Adhaar Number"
            />
          </div>
          <div>
            <input
              className="login__form__input"
              type="text"
              name="PAN"
              value={ PAN }
              id="PAN"
              onChange={handleChangeInput}
              placeholder="PAN Number" 
            />
          </div>
            <div className="row">
                <div className="col-md-6">
                        <div className="form-group files color">
                            <label>Adhaar photo(front) </label>
                            <input type="file" accept=".jpg, .jpeg, .png" className="form-control"  name="PANUpload" onChange={handleChangeInput} style={{borderRadius:'24px',marginTop:'3%'}}/>
                        </div>
                </div>
                <div className="col-md-6">
                        <div className="form-group files color">
                            <label>PAN card photo </label>
                            <input type="file" accept=".jpg, .jpeg, .png" className="form-control"  name="adharUpload" onChange={handleChangeInput} style={{borderRadius:'24px',marginTop:'3%'}}/>
                        </div>
                </div>
            </div><br></br>
            <div style={{marginTop:'4%'}}>
            <input
              className="login__form__input"
              type="text"
              name="shop"
              value={shop}
              id="shop"
              onChange={handleChangeInput}
              placeholder="Your Shop/Store Name"
            />
          </div>
            <div>
            <input
              className="login__form__input"
              type="text"
              name="store"
              value={store}
              id="store"
              onChange={handleChangeInput}
              placeholder="Store Address"
            />
          </div>
          <div>
      {loading ? (
        <p>Loading states...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div>
    
    <select name="state"  className="login__form__input" value={selectedState} onChange={handleChangeInput}>
        <option value="">Select a state</option>
        {states.map((state) => (
          <option key={state.state_id} value={state.state_name}>
            {state.state_name}
          </option>
        ))}
      </select>
        </div>
      )}
    </div>
          <div>
            <input
              className="login__form__input"
              type="pincode"
              name="pincode"
              value={pincode}
              id="pincode"
              onChange={handleChangeInput}
              placeholder="Store Pincode"
            />
          </div>
          <div>
            <input
              className="login__form__input"
              type="Bank"
              name="Bank"
              value={Bank}
              id="Bank"
              onChange={handleChangeInput}
              placeholder="Bank Account Number"
            />
          </div>
          <div>
            <input
              className="login__form__input"
              type="account"
              name="account"
              value={account}
              id="account"
              onChange={handleChangeInput}
              placeholder="Confirm Bank Account Number"
            />
          </div>
          <div>
            <input
              className="login__form__input"
              type="IFSC"
              name="IFSC"
              value={IFSC}
              id="IFSC"
              onChange={handleChangeInput}
              placeholder="IFSC Code"
            />
          </div>
          <div className="pass">
            <input
              className="login__form__input"
              type="hidden"
              name="otp"
              value={otp}
              id="otp"
              // onChange={handleChangeInput}
              placeholder="Enter 4 Digit OTP" 
            />
            {/* <small onClick={() => setTypePass(!typePass)}>
              {typePass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </small> */}
          </div>
          {/* <div className="pass">
            <input
              className="login__form__input"
              type={typeCfPass ? "text" : "otp"}
              name="cf_otp"
              value={cf_otp}
              id="cf_otp"
              onChange={handleChangeInput}
              placeholder="Confrim Password" 
            />
            <small onClick={() => setTypeCfPass(!typeCfPass)}>
              {typeCfPass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </small>
          </div> */}

          {/* <button style={{background:"#0dcaf0"}} className="login__form__submit" type="button">
            {loading ? <Spinner animation="border" size="sm" /> : "Send OTP"}
          </button> */}
   {renderSendOTPButton()}

          <button style={{ marginTop: "0.5rem" }} className="login__form__submit" type="submit">
            {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
          </button>

          <div style={{ marginTop: "1rem" }} className="login__form__forgot">
            <Link to="/login">Already have an account ? Login</Link>
          </div>
        </form>
      </div>
   
      {renderOTPModal()}

    </section>

    
  );
};


export default Register;
