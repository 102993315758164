import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineHeart, AiOutlineEye, AiFillHeart } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { addItemsToCart } from "../../redux/actions/cartActions";
import { ProductRating } from "../../components";
import { addItemsToWishlist } from "../../redux/actions/wishlistActions";
import ProductModal from "../../components/common/ProductModal/ProductModal";
import React, { useEffect, useState } from 'react';
import { Carousel, Card } from 'react-bootstrap';
import { FiHeart, FiStar } from 'react-icons/fi';

import './shop.scss'

const ShopProductSingle = ({ product, gridView }) => {
  const { cartItems } = useSelector((state) => state.cart);
  const [showmodal, setShowModal] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const { addToast } = useToasts();

  const addOrNot = cartItems?.find((item) => item.product === product._id);
  const dispatch = useDispatch();
  const addToCartHandler = () => {
    dispatch(addItemsToCart(product?._id, 1, addToast));
  };


  const { wishlistItems } = useSelector((state) => state.wishlist);

  const addWishlistOrNot = wishlistItems?.find(
    (item) => item.product === product._id
  );

  const addToWishlistHandler = () => {
    dispatch(addItemsToWishlist(product?._id, 1, addToast));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set the breakpoint width for mobile devices
    };

    handleResize(); // Check the initial viewport width

    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);
  const cond = product?.images?.length > 0;
  let content = null;
  let contentList = null;
  if (cond) {
    content = <img className="default-img" src={product?.images[0].thumbnailD} alt="" />;
    contentList = <img className="default-img" src={product?.images[0].thumbnailD} alt="" />;
  } else {
    content = <img className="default-img" src={product?.images.thumbnailD} alt="" />
    contentList = <img className="default-img" src={product?.images.thumbnailD} alt="" />;
  }
  //console.log(cond,"Prod",product);
  return (
    <>

      {gridView ? (

        <div className={`product-wrap`}>
          <div className="product-img">
            <Link to={`/product/${product._id}`}>
              {content}
            </Link>

            {/* <div className="product-action">
              <div className="pro-same-action pro-cart">
                {product?.Stock && product?.Stock > 0 ? (
                  <button
                    disabled={addOrNot?.quantity > 0}
                    onClick={addToCartHandler}
                    className="cart-btn"
                  >
                    <FiShoppingCart />
                    {addOrNot?.quantity > 0 ? "Added" : "Buy Now"}
                  </button>
                ) : (
                  <button disabled className="active">
                    Out of Stock
                  </button>
                )}
              </div>
              <div className="pro-same-action pro-quickview">
                <button onClick={() => setShowModal(true)} title="Quick View">
                  <AiOutlineEye />
                </button>
              </div>
            </div> */}
          </div>

          <div className="allProducts__container__item__content" style={{ padding: "4%" }}>
            <h4 style={{
              textTransform: 'capitalize', color: "#878787",

              fontWeight: "500"
            }}>{product.brand}</h4>
            <Card.Title>
              <Link to={`/product/${product._id}`}>
                <h3>
                  <strong>{product.name}</strong>
                </h3>
              </Link>
            </Card.Title>
            <Card.Text>


              <div className="off" style={{ color: "green" }}>
                11% Off
              </div>
              <div className="product-price">
                <div>
                  <s>&#8377;{(product.price) / 10}</s>
                </div>
                <span>&#8377;{product.price}</span>
              </div>
            </Card.Text>

          </div>

        </div>
      ) : (
              // <div className={`product-wrap product-grid-line-view`}>
              //   <div className="product-img-grid">
              //     <Link to={`/product/${product._id}`}>
              //       <img className="default-img" src={product?.images[0]?.thumbnail} alt="" />
              //     </Link>
              //   </div>
              //   <div className="product-content">
              //     <h3>
              //       <Link to={"/product/" + product._id}>{product.name}</Link>
              //     </h3>

              //     <div className="product-price">
              //       <span>${product.price}</span>
              //     </div>

              //     {/* <div className="product-rating">
              //       <ProductRating ratingValue={product.ratings} />({product.ratings})
              //     </div> */}

              //     {/* <div className="product-description">
              //       <p>{product.description.slice(0, 100) + "...."}</p>
              //     </div> */}

              //     <div className="product-description"
              //           dangerouslySetInnerHTML={{ __html: product.description.slice(0, 100) + "...." }}
              //         />


              //     <div className="buttons-group">
              //       <div className="buy-now">
              //         {product?.Stock && product?.Stock > 0 ? (
              //           <button
              //             disabled={addOrNot?.quantity > 0}
              //             onClick={addToCartHandler}
              //             className="cart-btn"
              //           >
              //             <FiShoppingCart />
              //             {addOrNot?.quantity > 0 ? "Added" : "Buy Now"}
              //           </button>
              //         ) : (
              //           <button disabled className="active">
              //             Out of Stock
              //           </button>
              //         )}
              //       </div>

              //       <div className="buy-now pro-wishlist">
              //         <button
              //           disabled={addWishlistOrNot?.quantity > 0}
              //           onClick={addToWishlistHandler}
              //           title={
              //             addWishlistOrNot?.quantity > 0
              //               ? "Added to Wishlist"
              //               : "Add to Wishlist"
              //           }
              //         >
              //           {addWishlistOrNot?.quantity > 0 ? (
              //             <AiFillHeart style={{ color: "red" }} />
              //           ) : (
              //             <AiOutlineHeart />
              //           )}
              //         </button>
              //       </div>
              //     </div>
              //   </div>
              // </div>
        <Card>
          <Card.body>
            <div className="prod-master">
              <div className="productS">
                <div className="product-thumbnail">
                  <Link to={`/product/${product._id}`}>
                    {contentList}
                  </Link>
                </div>
                <div className="product-details">
                  <div className="product-price">
                    <div>{product.brand}</div>
                  </div>
                  <h3>
                    <Link to={"/product/" + product._id}>{product.name}</Link>
                  </h3>
                  <div className="product-price">
                    <div className="off">10% Off</div>
                    <div><s>&#8377;{(product.price) / 10}</s></div>
                    <div>&#8377;{product.price}</div>
                  </div>


                  <div className="product-attributes">
                    {product.attributes?.map((a) => (
                      <span className="attribute"> {a.value}</span>
                    ))}
                  </div>
                  <div className="buttons-groupS">
                    <div className="buy-nowS">
                      {product?.Stock && product?.Stock > 0 ? (
                        <button
                          disabled={addOrNot?.quantity > 0}
                          onClick={addToCartHandler}
                          className="cart-btnS"
                        >
                          <FiShoppingCart />
                          {addOrNot?.quantity > 0 ? "Added" : "Add To Cart"}
                        </button>
                      ) : (
                        <button disabled className="active">
                          Out of Stock
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </Card.body>
        </Card>
      )}

      {/* quick view modal */}
      <ProductModal
        product={product}
        showmodal={showmodal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default ShopProductSingle;
