// razorpayActions.js

import axios from 'axios';
import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  CAPTURE_PAYMENT_REQUEST,
  CAPTURE_PAYMENT_SUCCESS,
  CAPTURE_PAYMENT_FAILURE,
} from '../constants/razorpayConstants';
import { BASE_URL } from "../../config";

// Action to create a new order
export const createRazorPayOrder = (amount,receipt,token) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ORDER_REQUEST });
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      console.log("Razor order ",amount,receipt,token);
    const response = await axios.post(`${BASE_URL}/api/razorpay/createorder`, {
      amount,
      receipt,
      config
    });

    dispatch({ type: CREATE_ORDER_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_ORDER_FAILURE, payload: error.response?.data || error.message });
  }
};

// Action to capture the payment
export const capturePayment = (paymentId) => async (dispatch) => {
  try {
    dispatch({ type: CAPTURE_PAYMENT_REQUEST });

    const response = await axios.post(`${BASE_URL}/api/razorpay/capturepayment/${paymentId}`);

    dispatch({ type: CAPTURE_PAYMENT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CAPTURE_PAYMENT_FAILURE, payload: error.response?.data || error.message });
  }
};
