import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Row, Col, Card, Button } from "react-bootstrap";
import { AiOutlineHeart, AiOutlineEye, AiFillHeart } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import ProductRating from "../../ProductRating/ProductRating";
import { addItemsToCart } from "../../../../redux/actions/cartActions";
import { buyNow } from "../../../../redux/actions/buyAction";
import { addItemsToWishlist } from "../../../../redux/actions/wishlistActions";
import { useState } from "react";
import ProductModal from "../../ProductModal/ProductModal";
import { useDispatch, useSelector } from "react-redux";
import ShopProductSingle from "../../../../pages/Shop/ShopProductSingle";
import "./single.scss"

import { useNavigate } from "react-router-dom";
const SingleProduct = ({ product }) => {
  const { cartItems } = useSelector((state) => state.cart);
  const token = useSelector((state) => state.userLogin?.userInfo?.access_token);
  const [showmodal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const { addToast } = useToasts();
  const navigate = useNavigate();
  const addOrNot = cartItems?.find((item) => item.product === product._id);
  console.log("TOKEN ---- ",token);
  const addToCartHandler = () => {
    dispatch(addItemsToCart(product?._id, 1, addToast,token));
   // navigate("/shipping/");
  };

  const addToBuy = () => {
    dispatch(buyNow(product?._id,token));
    console.log("buy data lciked",product?._id)
    navigate(`/shipping/:${product?._id}`);
  };

  const { wishlistItems } = useSelector((state) => state.wishlist);

  const addWishlistOrNot = wishlistItems?.find(
    (item) => item.product === product._id
  );

  const addToWishlistHandler = () => {
    dispatch(addItemsToWishlist(product?._id, 1, addToast));
  };

  return (

    // <Col xs={12} sm={6} md={6} lg={4}>
    //   <Card className="mb-4">
    //     <div className="CI"><Card.Img variant="top" src={product?.images?.thumbnailD} alt="" /></div>
    //     <Card.Body>
    //       <Card.Title>
    //         <Link to={`/product/${product._id}`}><h3><strong>{product.name} !!</strong></h3></Link>
    //       </Card.Title>
    //       <Card.Text>
    //       <div className="product-attributes">
    //         {/* Render the attributes */}
    //         {product.attributes?.map((attribute, index) => (
    //           <span key={index} className="attribute">
    //            <h5><strong>{attribute.key}</strong> - {attribute.value}</h5>
    //           </span>
    //         ))}
    //       </div>
    //       <div className="off" style={{ color:"green"}}>11% Off</div>
    //         <div className="product-price">
           
    //            <div><s>&#8377;{(product.price) / 10}</s></div>
    //           <span>&#8377;{product.price}</span>
    //         </div>
    //       </Card.Text>
    //       <div className="product-action">
    //         {/* <div className="pro-same-action pro-wishlist">
    //           <Button
    //             disabled={addWishlistOrNot?.quantity > 0}
    //             onClick={addToWishlistHandler}
    //             title={
    //               addWishlistOrNot?.quantity > 0
    //                 ? "Added to Wishlist"
    //                 : "Add to Wishlist"
    //             }
    //           >
    //             {addWishlistOrNot?.quantity > 0 ? (
    //               <AiFillHeart style={{ color: "red" }} />
    //             ) : (
    //               <AiOutlineHeart />
    //             )}
    //           </Button>
    //         </div> */}
    //         {/* <div className="pro-same-action pro-cart" style={{textAlign: "center"}}>
    //           {product?.Stock && product?.Stock > 0 ? (
    //             <Button
    //               disabled={addOrNot?.quantity > 0}
    //               onClick={addToCartHandler}
    //               className="cart-btn"
    //               title={
    //                 addOrNot?.quantity > 0 ? "Added to Cart" : "Add to Cart"
    //               }
    //             >
              
    //               {addOrNot?.quantity > 0 ? "Added" : "Add To Cart"}
    //             </Button>
    //           ) : (
    //             <Button disabled className="active">
    //               Out of Stock
    //             </Button>
    //           )}
    //         </div> */}
   
    //       </div>
    //     </Card.Body>
    //   </Card>
    //   {/* quick view modal */}
    //   <ProductModal
    //     product={product}
    //     showmodal={showmodal}
    //     setShowModal={setShowModal}
    //   />
    // </Col>
  
 
        <>
   
   <div key={product?._id} className="allProducts__container__item product-wrap">
   <div class="image-container">
   <img src={product?.images && product.images[0]?.thumbnailD || product?.images?.thumbnailD} alt="" />

    </div>
  <div className="allProducts__container__item__content" style={{ padding: "4%" }}>
    <h4 style={{ textTransform: 'capitalize',color: "#878787",
  
    fontWeight: "500"}}>{product.brand}</h4>
    <Card.Title>
      <Link to={`/product/${product._id}`}>
        <h3>
          <strong>{product.name} !!</strong>
        </h3>
      </Link>
    </Card.Title>
    <Card.Text>
      
      
      <div className="off" style={{ color: "green" }}>
        11% Off
      </div>
      <div className="product-price">
        <h4>
          <s>&#8377;{(product.price) / 10}</s>
        </h4>
        <span><h3>&#8377;{product.price}</h3></span>
      </div>
    </Card.Text>
    {/* <div className="allProducts__buttons">
      <div className="product__detail__info__buttons">
        {product?.Stock && product?.Stock > 0 ? (
          <button disabled={addOrNot?.quantity > 0} onClick={addToCartHandler}>
            {addOrNot?.quantity > 0 ? "Added In Cart" : "Add To Cart"}
          </button>
        ) : (
          <button disabled>Out of Stock</button>
        )}
      </div>
      <div className="product__detail__info__buttons-buy">
        {product?.Stock && product?.Stock > 0 ? (
          <button onClick={addToBuy} title="Buy Now">
            Buy Now
          </button>
        ) : (
          <button disabled className="active">
            Out of Stock
          </button>
        )}
      </div>
    </div> */}
  </div>
</div>

         
        </>
     
  
    );
};
  
export default SingleProduct;
