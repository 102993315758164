export const CREATE_AGENT_REQUEST = 'CREATE_AGENT_REQUEST';
export const CREATE_AGENT_SUCCESS = 'CREATE_AGENT_SUCCESS';
export const CREATE_AGENT_FAILURE = 'CREATE_AGENT_FAILURE';

export const CREATE_AGENT_RESET = 'CREATE_AGENT_RESET';

export const ALL_AGENTS_LOADING = 'ALL_AGENTS_LOADING';
export const ALL_AGENTS_SUCCESS = 'ALL_AGENTS_SUCCESS';
export const ALL_AGENTS_FAIL = 'ALL_AGENTS_FAIL';

// constants.js
export const UPLOAD_EXCEL_REQUEST = 'UPLOAD_EXCEL_REQUEST';
export const UPLOAD_EXCEL_SUCCESS = 'UPLOAD_EXCEL_SUCCESS';
export const UPLOAD_EXCEL_FAILURE = 'UPLOAD_EXCEL_FAILURE';

