
import { BUY_NOW, RESET,SUCCESS } from "../constants/buyConstants"; // Import the BUY_NOW and RESET constants

const initialState = {
  purchasedProduct: null,
  success: false,
};

const buyreducer = (state = initialState, action) => {
  console.log(action,state,"buy reducer")
  switch (action.type) {
    case BUY_NOW:
      return {
        ...state,
        purchasedProduct: action.payload,
        success: true,
      };
    case RESET:
      return {
        ...state,
        purchasedProduct: null,
        success: false,
      };
    default:
      return state;
  }
};

export default buyreducer;
