import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBrands } from   "../../../redux/actions/brandActions";
import { Row, Col, Container,Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
const BoxComponent = ({ image, text, isMobile}) => (
  <Col xs={6} sm={6} md={4} lg={3}>
  <div className={`box ${isMobile ? "mobile-box" : "desktop-box"}`}>
    <Link to={`/brandShop/${text}`}>
      <img style={{marginLeft:"-27px"}} src={image} alt={text} />
    </Link>
  </div>
</Col>
);


const Brands = () => {
  const dispatch = useDispatch();
  
  const { brands, error, loading } = useSelector(
    (state) => state.allBrands
  );
  const [isMobile, setIsMobile] = useState(false);
  const [callback, setCallback] = useState(false);
  const token = useSelector((state) => state.userLogin?.userInfo?.access_token);
  useEffect(() => {
    dispatch(getAllBrands(token));
    setIsMobile(window.innerWidth <= 1024);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    
  }, [dispatch, token]);
console.log("Loading brands",brands)
return (
  // <section className="company">
  //   <div className="company__container container-div grid">
  //     {brands?.map((brand, i) => (
  //       <div key={i}>
  //       <h1>Brand - {brand.name}</h1>
  //       </div>
  //     ))}
  //   </div>
  // </section>
  <section className="featured container-div">
      <div className="featured__container">
      <div classname="LR2" style={{marginTop: "6%",
      display: "flex",    marginTop: "6%",
      flexWrap: "nowrap",
      justifyContent: "space-between"}}>
        <h3 className="featured__title">Trending Brands </h3>
          {/* <button className="view-all-button">View All</button> */}
          <Link className="view-all-button" to={`/brandShop/`} >View All</Link>
        </div>
    
        <div className="featured__products">
          {loading ? (
            <Spinner
              style={{ marginLeft: "50%", marginTop: "5%" }}
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : error ? (
            <h2
              style={{
                color: "#333",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {error}
            </h2>
          ) : (
      
 <Row>
 {brands?.map((brand, i) => (

     <BoxComponent key={i} image={brand.logo} text={brand.name} isMobile={isMobile} />
   ))}
 </Row>

         )}
         </div>
       </div>
     </section>
   );
 };
 


export default Brands;
