// actions.js
import * as types from '../constants/otpConstants';
import axios from "axios";
import { BASE_URL } from "../../config";
export const otpRegisterRequest = (mobile, otp, info) => async (dispatch) => {

    dispatch({ type: types.OTP_REGISTER_REQUEST });

    const { data } = await axios.post(
        `${BASE_URL}/api/optRegister`,
        { mobile, otp, info }
      );
      console.log("XX - ",data," otp sent 1");

      dispatch({
        type: types.OTP_REGISTER_SUCCESS,
        payload:  { data, otpMatch: false },
      });
};

export const otpRegisterSuccess  = (mobile,otp) => async (dispatch) => {
    dispatch({ type: types.OTP_REGISTER_REQUEST });

    const { data } = await axios.post(
        `${BASE_URL}/api/optVerify`,
        { mobile,otp }
      );
      console.log("XX2 - ",data," otp2")
      dispatch({
        type: types.OTP_REGISTER_SUCCESS,
        payload:  { data, otpMatch: true },
      });
}

export const otpRegisterFailure = (error) => ({
  type: types.OTP_REGISTER_FAILURE,
  payload: error,
});
