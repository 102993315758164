import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams,useNavigate } from "react-router-dom";
import { AiFillHeart, AiFillStar, AiOutlineHeart } from "react-icons/ai";
import { Spinner, Row, Col } from "react-bootstrap";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useToasts } from "react-toast-notifications";
import {
  getAllProduct,
  getProductById,
  newReview,
} from "../../redux/actions/productActions";
import { addItemsToCart } from "../../redux/actions/cartActions";
import ReactStars from "react-rating-stars-component";

import { Footer, ProductRating } from "../../components";
import { FiShoppingCart } from "react-icons/fi";
import SingleProduct from "../../components/common/Products/SingleProduct/SingleProduct";
import { Helmet } from "react-helmet";
import { addItemsToWishlist } from "../../redux/actions/wishlistActions";
import { buyNow } from "../../redux/actions/buyAction";
import { clearErrors } from "../../redux/actions/orderActions";
import { NEW_REVIEW_RESET } from "../../redux/constants/productConstants";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
//import 'react-image-zoom/lib/ReactImageZoom.css';
import ReactImageZoom from "react-image-zoom";
import "../../styles/zoom.css";
import "./prod.scss"




const ProductDetail = () => {
  const sx = useSelector((state) => state.userLogin?.userInfo?.user);
  const token = useSelector((state) => state.userLogin?.userInfo?.access_token);

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const { productId } = useParams();
  const dispatch = useDispatch();
  const { product, loading, error } = useSelector((state) => state.productById);
  const [isMobile, setIsMobile] = useState(false);



  useEffect(() => {
    setIsMobile(window.innerWidth <= 1024);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);




  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [prodStatus,setProdStatus] = useState(false);
 

  // let images = [];
  // useEffect(() => {
  //   if (product.images && product.images.length > 0) {
  //     setImagesLoaded(true);

  //     images = product.images.map((image) => ({
  //       original: image.public_id,
  //       thumbnailD: image.thumbnailD,
  //       thumbnailDD: image.thumbnailDD,
  //     }));
  //   }
  // }, [product.images]);
  console.log("State STT: " + prodStatus);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);

  // Function to handle thumbnailD click
  const handleThumbnailClick = (index) => {
    setActiveThumbnailIndex(index);
  };
  const addToBuy = () => {
    dispatch(buyNow(product?._id,token));
    console.log("buy data lciked", product?._id)
    navigate(`/shipping/:${product?._id}`);
  };
  //console.log(product, "Products Details", imagesLoaded);

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;
  const { cartItems } = useSelector((state) => state.cart);

  const addOrNot = cartItems?.find((item) => item.product === product._id);

  const { addToast } = useToasts();

  const productsData = useSelector((state) => state.allProducts);

  const {
    success,
    error: reviewError,
    message,
    loading: reviewLoading,
  } = useSelector((state) => state.newReview);

  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = productsData;

  useEffect(() => {
    dispatch(getAllProduct(token));
  }, [dispatch,token]);

  useEffect(() => {
    dispatch(getProductById(productId,token));
  }, [productId, dispatch,token]);

  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    if (product.Stock <= quantity) return;

    const qty = quantity + 1;
    setQuantity(qty);
  };

  const decreaseQuantity = () => {
    if (1 >= quantity) return;

    const qty = quantity - 1;
    setQuantity(qty);
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(productId, quantity, addToast,token));
  };

  const { wishlistItems } = useSelector((state) => state.wishlist);

  const addWishlistOrNot = wishlistItems?.find(
    (item) => item.product === productId
  );

  const addToWishlistHandler = () => {
    dispatch(addItemsToWishlist(productId, 1, addToast,token));
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //const [gallery,galleryImages] =useState({product});
  useEffect(() => {
    console.log(product?.state,"---   DATA state for product",sx.sn,"and ",prodStatus)
   
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };
    product?.state?.some((element) => {
     if(sx.sn === element ){
       setProdStatus(true);
       console.log(element,"boyyyaa state for product",sx.sn,"and ",prodStatus);
       return true;
     }else{
       setProdStatus(false);
       console.log(element,"no STATE for product",sx.sn,"and ",prodStatus)
     }
 });

    handleResize(); // Call the handler initially
    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [prodStatus,sx,sx.sn,product.state]);
  useEffect(() => {
    if (error) {
      addToast(error, { appearance: "error", autoDismiss: true });
      dispatch(clearErrors());
    }

    if (reviewError) {
      addToast(reviewError, { appearance: "error", autoDismiss: true });
      dispatch(clearErrors());
    }

    if (success) {
      addToast(success, {
        appearance: "success",
        autoDismiss: true,
      });
      dispatch({ type: NEW_REVIEW_RESET });
    }
    dispatch(getProductById(productId,token));
  }, [dispatch, productId, error, reviewError, success, addToast, message,token]);

 console.log(product?.images?.thumbnailD," -  All Prods -  ",quantity)
  return (
    <>
    {prodStatus ?
      <section className="container-div section product">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Product - Detail</title>
        </Helmet>
        {loading ? (
          <Spinner
            style={{ marginLeft: "50%", marginTop: "5%" }}
            animation="border"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : error ? (
          <h2 style={{ color: "#333", fontWeight: "500", textAlign: "center" }}>
            {error}
          </h2>
        ) : (

          <div className="product__detail grid">
            {isMobile ?
              <div className="product__detail__img sProdM">
              {product.images && product.images.length > 0 ? (
                <div className="thumbnailD-imagesM">
                  {product?.images.map((image, index) => (
                    <div
                      key={index}
                      className={`thumbnailD-image ${index === activeThumbnailIndex ? 'active' : ''}`}
                      onClick={() => handleThumbnailClick(index)}
                    >
                      <img src={image.thumbnail} alt={`Thumbnail ${index}`} />
                    </div>
                  ))}
                </div>
              ) : 
              <div className="thumbnailD-images QQ">
                <div
                  key={1}
                  className={`thumbnailD-image ${0 === activeThumbnailIndex ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(1)}
                >
                  <img src={product?.images?.thumbnailD} alt={`Thumbnail ${1}`} />
                </div>
         
            </div>
              
              
              }
              {product.images && product.images.length > 0 ? (
                <div className="zoomable-imageM">
                  <img className="productMainImage" src={product.images[activeThumbnailIndex]?.public_id} alt="a1 IM" />
              
                </div>
              ) : 
              <div className="zoomable-image">
                 <img className="productMainImage" src={product?.images?.public_id} alt={`image ${1}`}/>
             </div>
              }
            </div>
            : 
            <div className="product__detail__img">
            {product.images && product.images.length > 0 ? (
              <div className="thumbnailD-images ">
                {product?.images.map((image, index) => (
                  <div
                    key={index}
                    className={`thumbnailD-image ${
                      index === activeThumbnailIndex  ? "active" : ""
                    }`}
                    onClick={() => handleThumbnailClick(index)}
                  >
                    <img src={image.thumbnailD} alt={`Thumbnail ${index}`} />
                  </div>
                ))}
              </div>
            ) : 
        null
            
            }
            {product.images && product.images.length > 0 ? (
              <div className="zoomable-image">
                {/* <ReactImageZoom
                 style={{
                  maxWidth: '80%', // Set the maximum width of the image to 100% of the container
                  maxHeight: '80%', // Set the maximum height of the image to 100% of the container
                  width: 'auto', // Ensure the image width adjusts based on the container size
                  height: 'auto', // Ensure the image height adjusts based on the container size
                }}
                  zoomWidth={600}
                  right={300}
                  img={product.images[activeThumbnailIndex]?.public_id}
                  
                /> */}
                <img className="productMainImage"
              // style={{ width: "59%", margin: "auto", display: "block" }}
              src={product.images[activeThumbnailIndex]?.public_id}
              alt={`image1`}
            />
              </div>
            ) : 
               <img 
              //  style={{width:"59%"}} 
               src={product?.images?.thumbnail} alt={`image1`} />
            }
          </div>}

            <div className="product__detail__info">
              <h2 className="product__detail__info-name">{product.name} </h2>

            
            
              <div className="product__detial__info__price">
                <span className="product__detail__info__price-count">
                &#8377; {product.price}
                </span>
              </div>
              {/* <div className="product__detail__info-rating">
                <ProductRating ratingValue={product.ratings} /> (
                {product.numOfReviews} Reviews)
              </div> */}

              <div className="product__detail__info-stock">
                <p style={{ fontWeight: "600" }}>
                  Status:{" "}
                  {product?.Stock && product?.Stock > 0
                    ? "In Stock"
                    : "Out of Stock"}
                </p>
              </div>
              <div className="product__detail__info__buttons-quantity">
                  <button onClick={decreaseQuantity} className="minus">
                    -
                  </button>
                  <input
                    className="input"
                    readOnly
                    type="number"
                    value={quantity}
                  />
                  <button onClick={increaseQuantity} className="add">
                    +
                  </button>
                </div>
              <div className="product__detail__info__buttons">
               
                <div className="product__detail__info__buttons-add">
                  {product?.Stock && product?.Stock > 0 ? (
                    <button
                      disabled={addOrNot?.quantity > 0}
                      onClick={addToCartHandler}
                    >
                      {addOrNot?.quantity > 0 ? "Added" : "Add To Cart"}
                    </button>
                  ) : (
                    <button disabled>Out of Stock</button>
                  )}
                </div>
        
                <div className="product__detail__info__buttons-buy">
                {product?.Stock && product?.Stock > 0 ? (
                  <button onClick={addToBuy} title="Buy Now">
                    Buy Now
                  </button>
                ) : (
                  <button disabled className="active">
                    Out of Stock
                  </button>
                )}
              </div>
              </div>

              <br></br>
              <div className="product__detail__info-desc  ">
              {product.attributes?.map((i)=>(
                <ul>
                  <li>
                  <h4><strong>{i.key}</strong> - {i.value}</h4>
                  </li>
                </ul>
              ))} 
             
              </div>
          
            </div>
            
          </div>
        )}

        {/* <div className="product-review-containter">
          <div className="product-review-left">
            {product?.reviews && product.reviews[0] ? (
              <div className="reviews">
                <h2>Ratings & Reviews</h2>
                {product?.reviews &&
                  product.reviews.map((review, index) => (
                    <div className="review-card" key={index}>
                      <div className="review-content">
                        <div className="review-person">
                          <img src={review?.avatar} alt="Person" />
                        </div>
                        <div className="review-description">
                          <div className="left">
                            <h4>{review.name}</h4>
                            <p>
                              {moment(review?.createdAt).format("MMMM Do YYYY")}
                            </p>
                            <p className="desc">{review.comment}</p>
                          </div>
                          <div className="right">
                            <ReactStars
                              count={5}
                              value={Number(review?.rating)}
                              edit={false}
                              size={24}
                              isHalf={true}
                              fullIcon={<AiFillStar />}
                              activeColor="#f35a24"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <p className="noReviews">No Reviews Yet</p>
            )}
          </div>
          {userInfo?.user ? (
            <form className="product-review-right" onSubmit={handleSubmit}>
              <h2>Add Review</h2>

              <div className="input-wrapper">
                <label>Title</label>
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter review title"
                  required
                />
              </div>

              <div className="input-wrapper">
                <label>Comment</label>
                <textarea
                  onChange={(e) => setComment(e.target.value)}
                  name="message"
                  cols="20"
                  rows="7"
                  placeholder="Write review"
                  required
                ></textarea>
              </div>

              <div className="input-wrapper">
                <label>Rating</label>
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={24}
                  isHalf={true}
                  fullIcon={<AiFillStar />}
                  activeColor="#f35a24"
                />
              </div>

              <button type="submit" className="button-primary review-btn">
                {reviewLoading ? "Loading.." : "Public Review"}
              </button>
            </form>
          ) : (
            <div className="product-review-right">
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Please{" "}
                <Link
                  style={{
                    color: "#f35a24",
                    textDecoration: "underline",
                  }}
                  to="/login"
                >
                  Login
                </Link>{" "}
                to submit review.
              </h2>
            </div>
          )}
        </div> */}
  <div className="product__description"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
        <div className={`related-products ${isMobile ? "mobile" : "desktop"}`}>
          <h2>Related Products</h2>
          <div className={`featured__products grid ${isMobile ? "" : "grid"}`}>
            <>
              {productsLoading ? (
                <Spinner
                  style={{ marginLeft: "50%", marginTop: "5%" }}
                  animation="border"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : productsError ? (
                <h2
                  style={{
                    color: "#333",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {productsError}
                </h2>
              ) : (
                <>
                { isMobile ?
            <div>
              <Row>
                  {products &&
                    products?.map((item) => {
                      return item.category === product.category ? (
                        <SingleProduct key={item?._id} product={item} />
                        ) : null;
                      })}
                      </Row>
                      </div>
                      :
                        products?.map((item) => {
                          return item.category === product.category ? (
                            <Col key={item?._id} sm={4} md={8} lg={12} style={{width:'250%'}}>
                            <SingleProduct product={item} />
                            </Col>
                          ): null;
                        })}
                       
                </>
              )}
            </>
          </div>
        </div>
      </section>
      :
      <section className="container-div section product">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Product - Detail </title>
      </Helmet>

      <div className=" ">
                    <h1>This Product is not Avialable in Your State </h1>
        </div>
      </section>
}
      <Footer />
    </>
  );
};

export default ProductDetail;
